// Modules.
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames'

// Components.
import Button from 'components/Button';
import Checkbox from 'components/Checkbox';
import Loader from 'components/Loader';

// Utils.
import { sendLink } from 'api/sendLink';
import GoogleAnalytics from 'utils/analytics';
import { localize } from 'utils/localization';

// Styles.
import styles from './LinkForm.module.scss';

const LinkForm = ( props ) => {
    const [ error, setError ] = useState( null );
    const [ success, setSuccess ] = useState( null );
    const [ privacy, setPrivacy ] = useState( false );
    const [ terms, setTerms ] = useState( false );
    const [ waitingForMessage, setWwaitingForMessage ] = useState( false );

    const resetForm = () => {
        props.reset( true );
        setPrivacy( false );
        setTerms( false );
    }

    const setErrorMessage = error => {
        if( error && error.statusCode ) {
            switch( error.statusCode ) {
                case 400:
                case 412:
                    props.communicationType === 'email' && setError( `${ localize( 'MODAL_ERROR_EMAIL_NOT_SET_INVALID' ) }` );
                    props.communicationType === 'SMS' && setError( `${ localize( 'MODAL_ERROR_SMS_NOT_SET_INVALID' ) }` );
                    break;

                case 401:
                    props.communicationType === 'email' && setError( `${ localize( 'MODAL_ERROR_EMAIL_INVALID_FORMAT' ) }` );
                    props.communicationType === 'SMS' && setError( `${ localize( 'MODAL_ERROR_SMS_NOT_SET_INVALID' ) }` );
                    break;

                case 21211:
                case 21614:
                    setError( `${ localize( 'MODAL_ERROR_SMS_NOT_SET_INVALID' ) }` );
                    break;

                case 500:
                case 21612:
                case 21408:
                case 21610:
                default :
                    setError( `${ localize( 'MODAL_ERROR_UNREACHABLE' ) }` );
                    break;
            }
        }
    }

    const submitJSON = () => {
        if (!privacy || waitingForMessage ) return;
        setError( null );
        setSuccess( null );

        if ( props.validate ) {
            const validateInput = props.validate( props.sendTo );
            if ( validateInput && validateInput.errorMessage ) {
                setErrorMessage( validateInput );
                return;
            };
        };
        var formData = {
            communicationType: props.communicationType,
            data: {
                sendTo: props.sendTo,
                body: `${ localize( 'MODAL_SMS_BODY' ) } ${ props.pinterestUrl }`,
                pinterestUrl: props.pinterestUrl,
                templateId: `${localize( 'MODAL_PINBOARD_EMAIL_TEMPLATE_ID' )}`,
            }
            // , sandbox: true,
        }
        GoogleAnalytics.export(props.communicationType);
        setWwaitingForMessage( true );
        sendLink( formData ).then(response => {
            if ( response && response.statusCode ) {
                switch ( response.statusCode ) {
                    case 200:
                    case 201:
                    case 202:
                        props.communicationType === 'email' && setSuccess( `${ localize( 'MODAL_SUCCESS_EMAIL' ) } ${ props.sendTo }` );
                        props.communicationType === 'SMS' && setSuccess( `${ localize( 'MODAL_SUCCESS_SMS' ) } ${ props.sendTo }` );
                        resetForm();
                        break;

                    default:
                        setError( localize( 'MODAL_ERROR_UNREACHABLE' ) );
                }
            }
            setWwaitingForMessage( false );
        }).catch( error => {
            setErrorMessage( error );
            setWwaitingForMessage( false );
        });
    }

    const onPrivacyTrigger = () => {
        GoogleAnalytics.button.click(`Privacy Policy Checkbox ${ !privacy ? 'Accepted' : 'Rejected' }`);
        setPrivacy(!privacy);
    };

    const onTermsTrigger = () => {
        GoogleAnalytics.button.click(`Terms Checkbox ${ !terms ? 'Accepted' : 'Rejected' }`);
        setTerms(!terms);
    };

    return (
        <>
            <div className={ styles.privacyLabel }>
                { props.children }
                <p className={ classnames({ [ styles.error ]: error, [ styles.success ]: success }) }>
                    { error && error }
                    { success && success }
                </p>
            </div>
            <div className={ styles.checkboxWrapper }>
                <div className={ classnames({ [ styles.terms ]: true, [ styles.hidden ]: props.hideTerms }) }>
                    <Checkbox
                        className={ styles.checkbox }
                        checked={ terms }
                        onClick={ onTermsTrigger }
                        id='terms'
                    />
                    <label htmlFor="terms">
                        { props.communicationType === 'email' && `${ localize('MODAL_PINBOARD_EMAIL_TERMS_LABEL')}` }
                        { props.communicationType === 'SMS' && `${ localize('MODAL_PINBOARD_SMS_TERMS_LABEL')}` }
                    </label>
                </div>
                <div className={ styles.privacyWrapper }>
                    <Checkbox
                        className={ styles.checkbox }
                        checked={ privacy }
                        onClick={ onPrivacyTrigger }
                        id='privacy'
                    />
                    <label htmlFor="privacy">
                        { `${ localize('MODAL_PINBOARD_PRIVACY_LABEL')}`}
                    </label>
                    <button className={ styles.privacyBtn } onClick={ props.privacyModalActive }>
                        { `${ localize('MODAL_PINBOARD_PRIVACY_LINK_TEXT')}` }
                    </button>
                </div>
            </div>
            {
                waitingForMessage ? 
                    <Loader /> 
                    : 
                    <Button
                    className={ styles.submitButton }
                    disabled={ ( privacy && terms && props.sendTo !== '' && !waitingForMessage ) ? false : true }
                    onClick={ submitJSON }
                    >
                        { localize('MODAL_PINBOARD_PINTEREST_BUTTON_COPY') }
                    </Button>
            }
        </>
    );
};

LinkForm.propTypes = {
    communicationType: PropTypes.string.isRequired,
    sendTo: PropTypes.string.isRequired,
    pinterestUrl: PropTypes.string,
    validate: PropTypes.func,
    privacyModalActive: PropTypes.func,
    hideTerms: PropTypes.bool,
    reset: PropTypes.func
}

LinkForm.defaultProps = {
    communicationType: null,
    sendTo: null,
    pinterestUrl: 'http://www.pinterest.com',
    validate: null,
    privacyModalActive: null,
    hideTerms: false,
    reset: null
}

export default LinkForm;
