import questions from 'json/questionnaire.config';

/**
* Retrieves the configuration for the question number.
*
* @param {number} index Question number to retrieve the data of.
* @return {?Array} Data for the question, or null if not found.
*/
export const getQuestionConfig = index => {
    return {
        questions: questions[index],
        previous: !!questions[index - 1],
        next: !!questions[index + 1],
    } || null;
};

/**
* Limits the answers to the question based on the top number of scores.
*
* @param {Object} question Question config for a single question.
* @param {Object} scoreboard Scores for each item.
* @param {number} limit Number of items that is limited in the answers.
* @return {Object} Configuration fo the question with the limitations.
*/
export const getQuestionWithLimits = (question, scoreboard) => {
    const updatedQuestion = { ...question };
    const sortedItems = sortItemsByScores(scoreboard);
    const items = sortedItems.slice(0, question.limitation);

    for (let i = updatedQuestion.answers.length - 1; i >= 0; i--) {
        const match = updatedQuestion.answers[i].points.some(item => items.indexOf(item) >= 0);
        if (!match) {
            updatedQuestion.answers.splice(i, 1);
        }
    }

    return updatedQuestion;
};

/**
* Sorts a scoreboard object of type { itemkey: score } into an array of items sorted by the score.
*
* @param {Object} scoreboard Scores of each item.
* @return {Array} Array of items sorted by their score from highest to lowest.
*/
const sortItemsByScores = scoreboard => {
    // First, build an array of arrays where the index will be based on the...
    // ...the score. This allows us to organize items with the same score.
    const itemsByScore = [];

    Object.keys(scoreboard).forEach(key => {
        const currentScore = scoreboard[key];
        if (!itemsByScore[currentScore]) {
            itemsByScore[currentScore] = [key];
        } else {
            itemsByScore[currentScore].push(key);
        }
    });

    // Next, for a bit of randomness, we'll shuffle each array of scores.
    itemsByScore.forEach(items => {
        if (items.length > 1) {
            for (let i = items.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * i);
                const temp = items[i];
                items[i] = items[j];
                items[j] = temp;
            }
        }
    });

    // Finally, return the flattened array.
    return itemsByScore.flat().reverse();
};
