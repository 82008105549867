import TweenMax, { Sine, Power3 } from "gsap";
import SplitText from 'utils/SplitText';

// import { sectionAnimateIn, sectionAnimateOut, chainAnimations } from 'utils/animations';

/**
* Animation handler for the Home section
*
* @param {Object} node The section's DOM object.
* @param {Function} done The callback function after animations completed.
* @param {boolean} show Flag indicating whether the section is showing or hiding.
* @param {Object} refs A key-DOM dictionary to pass the references of child components.
*/
export const animateHandler = (node, done, show, refs) => {

    if (show) {
        Promise.all([
            new Promise(resolve => {
                TweenMax.fromTo(node, 2, { opacity: 0 }, { opacity: 1, ease: Sine.easeOut, onComplete: () => resolve(true) });
            }),
            new Promise(resolve => {

                const split = new SplitText(refs.titleRef.current, { type: 'lines' });

                TweenMax.staggerFromTo(split.lines, 2, {
                    opacity: 0,
                    y: 15,
                }, {
                    y: 0,
                    opacity: 1,
                    ease: Power3.easeOut,
                    delay: 1.5,
                }, 0.15, () => resolve(true));

            }),
            refs.buttonRef.current.onAnimateIn(1.75),
            refs.subtitleRef.current.onAnimateIn(1),
            refs.languageRef.current.onAnimateIn(1),
        ]).then(done);
    }

};
