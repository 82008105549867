// Modules.
import { TweenMax, Power3 } from 'gsap';

// Utils.
import SplitText from 'utils/SplitText';

/**
* Animation function for animating in a section.
*
* @param {Object} node The section's DOM object.
* @return {Promise} Promise-wrapped animation.
*/
export const sectionAnimateIn = node => (
    new Promise(() => {
        TweenMax.fromTo(node, 0.5,
            { autoAlpha: 0, y: -50 },
            { autoAlpha: 1, y: 0 }
        );
    })
);

/**
* Animation function for animating out a section.
*
* @param {Object} node The section's DOM object.
* @return {Promise} Promise-wrapped animation.
*/
export const sectionAnimateOut = node => (
    new Promise(() => {
        TweenMax.fromTo(node, 0.5,
            { autoAlpha: 1, y: 0 },
            { autoAlpha: 0, y: -50 }
        );
    })
);

/**
* Utility function to chain an array of animations together using promises.
*
* @param {Function[]} animations Array of the animation functions to chain together.
* @return {Promise} Promise chain of animations
*/
export const chainAnimations = animations => {
    if (animations.length === 1) return animations[0]();

    const animation = animations.shift();
    return animation().then(() => {
        chainAnimations(animations);
    });
};

/**
* Utility function to calculate the delay to stagger an array of animations.
*
* @param {Function[]} animations Array of the animation functions to chain together.
* @param {float} stagger Float value of the time to stagger each animation.
* @return {Function[]} Array of the staggered animation function calls.
*/
export const staggerAnimations = (animations, stagger = 0.25) => {
    return animations.map((childAnimation, index) => {
        const delay = stagger * index + 1;
        return childAnimation(delay);
    });
};

/**
* Create an animate in animation on copy using split text.
*
* @param {?} element Element representation (generally a ref to it).
* @return {Promise} Promise of the animation being run.
*/
export const copySplitAnimateIn = (element, delay = 0) => {
    return new Promise(resolve => {
        const split = new SplitText(element, { type: 'lines' });
        TweenMax.staggerFromTo(
            split.lines, 3,
            { y: 100, autoAlpha: 0 },
            { y: 0, autoAlpha: 1, ease: Power3.easeOut, delay },
            0.2, () => resolve(true)
        );
    });
};

/**
* Create an animate out animation on copy using split text.
*
* @param {?} element Element representation (generally a ref to it).
* @return {Promise} Promise of the animation being run.
*/
export const copySplitAnimateOut = (element, delay = 0) => {
    return new Promise(resolve => {
        const split = new SplitText(element, { type: 'lines' });
        TweenMax.staggerTo(
            split.lines, 1.5,
            { y: -100, autoAlpha: 0, ease: Power3.easeIn, delay },
            0.15, () => resolve(true)
        );
    });
};
