export const LAMBDA_HOST = 'https://wx6o5i7xci.execute-api.us-west-2.amazonaws.com/jcp_pinterest_stage';

export const sendLink = ( request ) => {
    return fetch(`${ LAMBDA_HOST }/sendlink`, {
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify( request ),
    })
    .then( response => response.json())
    .then( json => {
        if (json.errorType) return Promise.reject( JSON.parse( json.errorMessage ));
        return json;
    } )
    .catch ( error => {
        return {
            statusCode: 500,
            errorMessage: error,
        }
    });
};