import ReactGA from 'react-ga';

const GA_TRACKINGBOX = {
    id: 'UA-150295165-1',
    name: 'Trackingbox',
};

const GA_TRACKINGPENNEY = {
    id: 'UA-150028025-1',
    name: 'JCPenney'
};

const trackers = [ GA_TRACKINGBOX.name, GA_TRACKINGPENNEY.name ];

const GoogleAnalytics = {
    button: {
        click: label => ReactGA.event({ category: 'User', action: 'Clicked Button', label }, trackers),
    },
    calls: () => ReactGA.testModeAPI.calls,
    question: {
        loaded: (number, option) => {
            ReactGA.event({
                category: 'Question',
                action: 'Loaded',
                label: `Question: ${ number }, Option: ${ option }`,
                nonInteraction: true,
            }, trackers);
        },
        answer: {
            select: label => {
                ReactGA.event({
                    category: 'Question',
                    action: 'Selected Answer',
                    label: `Answer: ${ label }`,
                }, trackers);
            },
        },
        completed: number => {
            ReactGA.event({ category: 'Question', action: 'Completed', label: `Question: ${ number }` }, trackers);
        },
    },
    event: (category, action, options = {}) => {
        ReactGA.event({ category, action, ...options }, trackers);
    },
    exit: path => {
        ReactGA.event({ category: 'User', action: 'Exit Experience', label: path }, trackers);
    },
    export: type => {
        ReactGA.event({ category: 'User', action: 'Export Pincode', label: type }, trackers);
    },
    init: () => {
        const GA_OPTIONS = { debug: false };
        ReactGA.initialize([
            {
                trackingId: GA_TRACKINGBOX.id,
                gaOptions: { name: GA_TRACKINGBOX.name },
            },
            {
                trackingId: GA_TRACKINGPENNEY.id,
                gaOptions: { name: GA_TRACKINGPENNEY.name },
            },
        ], GA_OPTIONS);
    },
    pageview: path => {
        ReactGA.pageview(path, trackers);
    },
    start: () => {
        ReactGA.event({ category: 'User', action: 'Starts Experience' }, trackers);
    },
    style: style => {
        ReactGA.event({
            category: 'Results',
            action: 'Style Result',
            label: style,
            nonInteraction: true,
        }, trackers);
    },
    timeout: path => {
        ReactGA.event({ category: 'Timeout', action: 'Inactivity', label: `From ${ path }` }, trackers);
    },
};

// @TODO: Remove after development.
// window.ga = GoogleAnalytics;

export default GoogleAnalytics;
