// Modules.
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Utils.
import codes from 'json/country-codes.json';

// Styles.
import styles from './CountryCodeSelector.module.scss';

const CountryCodeSelector = ({ defaultValue, onChange }) => {
    const [ selected, setSelected ] = useState(null);
    const [ selecting, setSelecting ] = useState(false);

    const handleOnClick = useCallback(value => {
        setSelected(value);
        setSelecting(false);
    }, []);

    useEffect(() => {
        onChange(selected ? selected.dial_code : '');
    }, [ selected, onChange ]);

    useEffect(() => {
        const filtered = codes.find(code => code.code.toLowerCase() === defaultValue.toLowerCase());
        if (filtered) {
            setSelected(filtered);
        }
    }, [ defaultValue, handleOnClick ]);

    return (
        <div className={ styles.CountryCodeSelector }>
            <div className={ styles.label } onClick={ () => setSelecting(!selecting) }>
                { selected ? selected.dial_code : '' }
            </div>
            { selecting &&
                <div className={ styles.select }>
                    { codes.map((code, index) => (
                        <div key={ index } onClick={ () => handleOnClick(code) }>
                            { `${ code.name } (${ code.dial_code })` }
                        </div>
                    )) }
                </div>
            }
            <div className={ styles.arrow } />
        </div>
    );
};

CountryCodeSelector.propTypes = {
    defaultValue: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

CountryCodeSelector.defaultProps = {
    defaultValue: 'US',
};

export default CountryCodeSelector;
