// Modules.
import React, { useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';

// Utils.
import { onAnimateIn, onAnimateOut } from './PersonaSection.animations';
import GoogleAnalytics from 'utils/analytics';
import { localize } from 'utils/localization';
import { getPersonaDescription, getPersonaVideo } from 'utils/persona';

// Styles.
import styles from './PersonaSection.module.scss';

const LOAD_TIME_SECONDS = 6;

const PersonaSection = ({ history, location, match }) => {
    const sectionIn = match !== null;

    const search = location.search;
    const params = new URLSearchParams(search);
    const persona = params.get('type');

    if (!persona) {
        console.error('[PersonaSection] No persona type found in query params.');
        history.push('/');
    }

    const headerRef = useRef(null);
    const descriptionRef = useRef(null);
    const loaderRef = useRef(null);
    const loaderCopyRef = useRef(null);
    const videoRef = useRef(null);
    const timeoutRef = useRef(null);

    const getRefs = () => ({
        header: headerRef.current,
        description: descriptionRef.current,
        loader: loaderRef.current,
        loaderCopy: loaderCopyRef.current,
    });

    const startCountdown = () => {
        if (!videoRef.current) return;

        videoRef.current.pause();
        timeoutRef.current = setTimeout(
            () => {
                videoRef.current.play();
                onAnimateOut(getRefs());
            },
            LOAD_TIME_SECONDS * 1000
        );
    };

    useEffect(() => {
        GoogleAnalytics.pageview(`${ location.pathname }${ location.search }`);
        GoogleAnalytics.style(persona);

        videoRef.current.addEventListener('ended', () => history.push({ pathname: '/styleboard', search: `?type=${ persona }` }));

        return () => {
            if (timeoutRef.current) clearTimeout(timeoutRef.current);
        };
    }, [ history, location, persona ]);

    return (
        <Transition
            appear
            unmountOnExit
            in={ sectionIn }
            addEndListener={ (node, done) => {
                const callback = () => {
                    startCountdown();
                    done();
                };
                if (sectionIn) onAnimateIn({ ...getRefs(), node }, callback);
            } }
        >
            <section className={ styles.PersonaSection }>
                <div className={ styles.backvid }>
                    <video src={ getPersonaVideo(persona) } ref={ videoRef } autoPlay muted />
                </div>

                <div className={ styles.container }>
                    <h1 ref={ headerRef }>{ localize('PERSONA_PAGE_DISCOVER') } { localize(persona) }</h1>
                    <p ref={ descriptionRef } className={ styles.description }>
                        { localize(`${ getPersonaDescription(persona) }`) }
                    </p>

                    <div className={ styles.loader } ref={ loaderRef }>
                        <h4 ref={ loaderCopyRef }>{ localize('PERSONA_PAGE_PINBOARD_LOADING') }</h4>
                    </div>
                </div>
            </section>
        </Transition>
    );
};

PersonaSection.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
};

PersonaSection.defaultProps = {
    history: null,
    location: null,
    match: null,
};

export default PersonaSection;
