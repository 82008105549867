// Modules.
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { TweenMax, Circ } from 'gsap';

// Components.
import PinboardClickTile from 'components/PinboardClickTile';

// Utils.
import useTransitions from 'hooks/useTransitions';

// Styles.
import styles from './Pinboard.module.scss';

const CTA_CONSTANT = 'CTA_CONSTANT';
const CTA_INDEX = 7;

const Pinboard = React.forwardRef(
    ({ images, onButtonClick }, animRef) => {
        const pins = [ ...images ];
        pins.splice(CTA_INDEX, 0, CTA_CONSTANT);

        const pinRefs = useRef([]);

        const onAnimationInit = () => {};

        const onAnimateIn = (delay = 0) => {
            const even = pinRefs.current.filter((_, index) => index % 2 === 0);
            const odd = pinRefs.current.filter((_, index) => index % 2 !== 0);

            return new Promise(resolve => {
                const duration = 3;
                const stagger = 0.15;
                const from = { x: '150vw', autoAlpha: 0 };
                const to = { x: '0vw', autoAlpha: 1, ease: Circ.easeOut, delay, onComplete: () => resolve(true) };

                TweenMax.staggerFromTo(even, duration, from, to, stagger);
                TweenMax.staggerFromTo(odd, duration, from, to, stagger);
            });
        };

        const onAnimateOut = (delay = null) => (
            new Promise(resolve => {

            })
        );

        if (animRef) {
            useTransitions(animRef, onAnimationInit, onAnimateIn, onAnimateOut);
        }

        return (
            <div className={ styles.Pinboard }>
                { pins.map((pin, index) => {
                    if (!pin) return null;
                    if (pin === CTA_CONSTANT) {
                        return (
                            <div key={ index } ref={ element => pinRefs.current[index] = element }>
                                <PinboardClickTile onClick={ onButtonClick } />
                            </div>
                        );
                    }
                    return (
                        <div
                            key={ index }
                            ref={ element => pinRefs.current[index] = element }
                            className={ styles.image }
                            style={ { backgroundImage: `url(${ pin })` } }
                        />
                    );
                }) }
            </div>
        );
    }
);

Pinboard.propTypes = {
    images: PropTypes.array.isRequired,
    onButtonClick: PropTypes.func,
};

Pinboard.defaultProps = {
    onButtonClick: null,
};

export default Pinboard;
