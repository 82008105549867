// Modules.
import React, { useRef, useEffect } from 'react';
import TweenMax, { Power3 } from 'gsap';

// Components.
import LogoJCPenney from 'components/LogoJCPenney';

// Styles.
import styles from './HeaderBar.module.scss';

const HeaderBar = () => {

    const node = useRef(null);

    useEffect(() => {
        TweenMax.fromTo(node.current, 1.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeOut, delay: 0.75 });
    }, []);

    return (
        <div className={ styles.HeaderBar } ref={ node }>
            <div className={ styles.logo }>
                <LogoJCPenney />
            </div>
        </div>
    );

};

export default HeaderBar;
