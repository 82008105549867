// Modules.
import React, { useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TweenMax, { Power3, Expo } from 'gsap';

// Components.
import Slider from 'components/Slider';
import Selectoid from 'components/Selectoid';

// Utils.
import { QuestionType } from 'utils/constants';
import { QuizContext } from 'utils/context';
import { localize } from 'utils/localization';
import { getQuestionWithLimits } from 'utils/questionnaire';
import useTransitions from 'hooks/useTransitions';
import usePrevious from 'hooks/usePrevious';
import SplitText from 'utils/SplitText.js';

// Styles.
import styles from './Question.module.scss';

const Question = React.forwardRef(
    ({ config, onChange, value, index }, animRef) => {

        const ref = useRef(null);
        const titleRef = useRef(null);
        const sliderRef = useRef(null);
        const selectoidRef = useRef(null);
        const context = useContext(QuizContext);
        const [ question, setQuestion ] = useState(null);
        const prevQuestion = usePrevious(question);

        useEffect(() => {

            if (!config.limitation) setQuestion(config);
            else {

                const limited = getQuestionWithLimits(config, context.scoreboard);
                setQuestion(limited);

            }

        }, [config, context.scoreboard]);

        const onAnimationInit = () => {
            // ...
        };

        const onAnimateIn = (index) => {

            const promises = [];

            titleRef.current && promises.push(new Promise(resolve => {

                const { top, height } = titleRef.current.getBoundingClientRect();
                const offset = (window.innerHeight / 2) - top - (height / 2);
                const split = new SplitText(titleRef.current, { type: 'lines' });

                TweenMax.set(titleRef.current, { opacity: 1 });

                if (index === 0) {
                    TweenMax.staggerFromTo(split.lines, 1, {
                        opacity: 0,
                        y: offset + 15,
                    }, {
                        opacity: 1,
                        y: offset,
                        ease: Power3.easeOut,
                        delay: 0.5,
                    }, 0.15, () => {
                        TweenMax.staggerTo(split.lines, 2, {
                            y: 0,
                            ease: Expo.easeInOut,
                            delay: 0.25,
                        }, 0.05, () => resolve(true));
                    });
                } else {
                    TweenMax.staggerFromTo(split.lines, 1, {
                        opacity: 0,
                        y: 15,
                    }, {
                        y: 0,
                        opacity: 1,
                        ease: Power3.easeOut,
                        delay: 0.15,
                    }, 0.15, () => resolve(true));
                }

            }));

            sliderRef.current && promises.push(sliderRef.current.onAnimateIn((index === 0) ? 3 : 0.25));
            selectoidRef.current && promises.push(selectoidRef.current.onAnimateIn((index === 0) ? 3 : 0.25));

            return Promise.all(promises);

        };

        const onAnimateOut = () => {

            const promises = [];

            titleRef.current && promises.push(new Promise(resolve => {

                const split = new SplitText(titleRef.current, { type: 'lines' });

                if (split.lines.length > 1) {
                    TweenMax.staggerTo(split.lines, 1, { y: -15, opacity: 0, ease: Power3.easeIn, delay: sliderRef.current ? 1.6 : 0.85 }, 0.15, () => resolve(true));
                } else {
                    TweenMax.to(titleRef.current, 1, { y: -15, opacity: 0, ease: Power3.easeIn, delay: sliderRef.current ? 1.75 : 1, onComplete: () => resolve(true) });
                }

            }));

            sliderRef.current && promises.push(sliderRef.current.onAnimateOut());
            selectoidRef.current && promises.push(selectoidRef.current.onAnimateOut());

            return Promise.all(promises);

        };

        useEffect(() => {
            if (!prevQuestion || (prevQuestion.key !== question.key)) onAnimateIn(index);
        }, [question, prevQuestion, index]);

        if (animRef) {
            useTransitions(animRef, onAnimationInit, onAnimateIn, onAnimateOut);
        }

        const renderQuestionUI = () => {
            switch (question.type) {
                case QuestionType.SLIDER:
                    return (
                        <Slider
                            answers={ question.answers.map(
                                (answer, index) => ({
                                    label: localize(answer.key),
                                    img: answer.img || null,
                                    value: index,
                                })
                            ) }
                            cta={ localize('CORE_DRAG_THE_CIRCLE') }
                            onSelect={ onChange }
                            opts={ {
                                width: context.width < 1366 ? 800 : 1200,
                                height: context.width < 1366 ? 80 : 120,
                                resolution: 2,
                            } }
                            value={ value }
                            ref={ sliderRef }
                            key={ question.key }
                        />
                    );

                case QuestionType.IMAGE_TAP:
                case QuestionType.FINGERS_IMAGE_TAP:
                default:
                    return (
                        <Selectoid
                            answers={ question.answers }
                            onChange={ onChange }
                            value={ value }
                            fingers={ question.type === QuestionType.FINGERS_IMAGE_TAP }
                            ref={ selectoidRef }
                            key={ question.key }
                        />
                    );
            }
        };

        return (
            <div className={ styles.Question } ref={ ref }>
                { question &&
                    <>
                        <h2 ref={ titleRef } key={ question.key }>{ localize(question.key) }</h2>
                        <div className={ styles.answers }>{ renderQuestionUI() }</div>
                    </>
                }
            </div>
        );
    }
);

Question.propTypes = {
    config: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    index: PropTypes.number,
};

Question.defaultProps = {
    value: null,
    index: 0,
};

export default Question;
