// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TweenMax, { Power3 } from 'gsap';

// Styles.
import styles from './Selectoid.module.scss';

// Utils.
import { localize } from 'utils/localization';

export default class Selectoid extends React.Component {

    static get propTypes() {
        return {
            answers: PropTypes.array.isRequired,
            onChange: PropTypes.func,
            value: PropTypes.number,
            fingers: PropTypes.bool,
        }
    }

    static get defaultProps() {
        return {
            onChange: f => f,
            value: null,
            fingers: false,
        }
    }

    constructor(props) {

        super(props);

        this.cards   = [];
        this.titles  = [];
        this.fingers = [];

    }

    onAnimationInit = () => {

        const pivot = (this.cards.length - 1) / 2;

        for (let i = 0; i < this.cards.length; i++) {

            TweenMax.set(this.titles[i], { y: 30, opacity: 0 });

            if (this.props.fingers) TweenMax.set(this.fingers[i], { y: 30, opacity: 0 });

            switch (true) {
                case i < pivot:
                    TweenMax.set(this.cards[i], { opacity: 0, x: -90 });
                    break;
                case i === pivot:
                    TweenMax.set(this.cards[i], { opacity: 0, y: 0 });
                    break;
                case i > pivot:
                    TweenMax.set(this.cards[i], { opacity: 0, x: 90 });
                    break;
                default:
                    break;
            }

        }

    }

    onAnimateIn = (delay = null) => {

        this.enableTouch();

        const pivot = (this.cards.length - 1) / 2;

        for (let i = 0; i < this.cards.length; i++) {

            const offset = Math.ceil(Math.abs(i - pivot));

            TweenMax.to(this.cards[i], 1.5, { opacity: 1, x: 0, y: 0, ease: Power3.easeOut, delay: delay + (offset * 0.2) });
            TweenMax.to(this.titles[i], 1.5, { opacity: 1, y: 0, ease: Power3.easeOut, delay: delay + ((i * 0.2) + (this.props.fingers ? 1 : 0.5)) });

            if (this.props.fingers) TweenMax.to(this.fingers[i], 1.5, { opacity: 1, y: 0, ease: Power3.easeOut, delay: delay + ((i * 0.2) + 0.5) });

        }

    }

    onAnimateOut = () => {

        this.disableTouch();

        const { fingers } = this.props;
        const duration = 0.3;

        for (let i = 0; i < this.cards.length; i++) {

            TweenMax.to(this.cards[i], duration, { scale: 1, opacity: 1, y: '0vw' });
            TweenMax.to(this.titles[i], duration, { color: '#24272a', y: '0vw', opacity: 1 });

            if (fingers) TweenMax.to(this.fingers[i], duration, { opacity: 0.75, scale: 1 });

        }


        const pivot = (this.cards.length - 1) / 2;

        return new Promise((resolve) => {

            for (let i = 0; i < this.cards.length; i++) {

                // Inverted offset.
                const offset = Math.abs(Math.ceil(Math.abs(i - pivot)) - Math.ceil(pivot));

                switch (true) {
                    case i < pivot:

                        TweenMax.to(this.cards[i], 1.5, { opacity: 0, x: -90, ease: Power3.easeOut, delay: 0.5 + (offset * 0.2) });
                        TweenMax.to(this.titles[i], 1.5, { opacity: 0, x: -90, ease: Power3.easeOut, delay: (fingers ? 0.7 : 0.6) + (offset * 0.2), onComplete: () => {
                            (i === (pivot - 0.5)) && resolve(true);
                        }});

                        if (fingers) TweenMax.to(this.fingers[i], 1.5, { opacity: 0, x: -90, ease: Power3.easeOut, delay: 0.6 + (offset * 0.2) });

                        break;
                    case i === pivot:

                        TweenMax.to(this.cards[i], 1.5, { opacity: 0, ease: Power3.easeOut, delay: 0.5 + (offset * 0.2) });
                        TweenMax.to(this.titles[i], 1.5, { opacity: 0, ease: Power3.easeOut, delay: (fingers ? 0.7 : 0.6) + (offset * 0.2), onComplete: () => resolve(true)});

                        if (fingers) TweenMax.to(this.fingers[i], 1.5, { opacity: 0, ease: Power3.easeOut, delay: 0.6 + (offset * 0.2) });

                        break;
                    case i > pivot:

                        TweenMax.to(this.cards[i], 1.5, { opacity: 0, x: 90, ease: Power3.easeOut, delay: 0.5 + (offset * 0.2) });
                        TweenMax.to(this.titles[i], 1.5, { opacity: 0, x: 90, ease: Power3.easeOut, delay: (fingers ? 0.7 : 0.6) + (offset * 0.2) });

                        if (fingers) TweenMax.to(this.fingers[i], 1.5, { opacity: 0, x: 90, ease: Power3.easeOut, delay: 0.6 + (offset * 0.2) });

                        break;
                    default:
                        break;
                }

            }

        });

    }

    componentDidMount() {
        this.onAnimationInit();
    }

    enableTouch = () => {
        this.node.style.pointerEvents = 'auto';
    }

    disableTouch = () => {
        this.node.style.pointerEvents = 'none';
    }

    onChange = index => {

        const { fingers } = this.props;
        const duration = 0.3;

        for (let i = 0; i < this.cards.length; i++) {

            TweenMax.to(this.cards[i], duration, { scale: 1, opacity: .25, y: '0vw' });
            TweenMax.to(this.titles[i], duration, { color: '#24272a', y: '0vw', opacity: 0.25 });

            if (fingers) TweenMax.to(this.fingers[i], duration, { opacity: 0.75, scale: 1 });

        }

        TweenMax.to(this.cards[index], duration, { scale: 1.1, opacity: 1, y: this.props.fingers ? '-1.25vw' : '0vw' });
        TweenMax.to(this.titles[index], duration, { color: '#e71324', opacity: 1 });

        fingers ?
        TweenMax.to(this.fingers[index], duration, { opacity: 1, scale: 1.05 }) :
        TweenMax.to(this.titles[index], duration, { y: '1.25vw', delay: 0.04 }) ;

        this.props.onChange(index);

    }

    render() {

        const { answers, value, fingers } = this.props;
        const nodeClasses = classnames({
            [styles.Selectoid]: true,
            [styles.hasFingers]: fingers,
        });

        return (
            <div className={ nodeClasses } ref={ el => this.node = el }>
                {
                    answers.map((answer, key) => {

                        const answerClasses = classnames({
                            [styles.answer]: true,
                            [styles.selected]: key === value,
                            [styles.idle]: value === null,
                        });

                        return (
                            <div className={ answerClasses } key={ key } onClick={ () => this.onChange(key) } onTouchEnd={ () => this.onChange(key) }>
                                {
                                    !fingers &&
                                    <div className={ styles.card } ref={ el => this.cards[key] = el }>
                                        <div className={ styles.image } style={{ backgroundImage: `url(${ answer.img })` }} />
                                    </div>
                                }
                                {
                                    fingers && <img className={ styles.cutout } src={ answer.img } alt={ answer.key } ref={ el => this.cards[key] = el } />
                                }
                                {
                                    fingers &&
                                    <div className={ styles.finger } ref={ el => this.fingers[key] = el }>
                                        <div className={ styles.outer }>
                                            <div className={ styles.inner }>
                                                <img src="/images/select.png" alt="Select" />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <h3 ref={ el => this.titles[key] = el }>{ localize(answer.key) }</h3>
                            </div>
                        );

                    })
                }
            </div>
        );

    }

}
