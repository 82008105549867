class IdleManager {

    constructor() {
        this.idleTimer = null;
    }

    getTimeout() {
        return this.idleTimer;
    }

    startIdleTimer( callback, time = 240000 )  {
        if( this.idleTimer ) this.clearIdleTimer();
        this.idleTimer = setTimeout( () => {
                callback();
                this.clearIdleTimer();
            }, time);
    }

    resetIdleTimer( callback )  {
        clearTimeout( this.idleTimer );
        this.startIdleTimer( callback );
    };

    clearIdleTimer()  {
        clearTimeout( this.idleTimer );
    }
}

export default IdleManager;
