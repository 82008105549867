// Modules.
import { TweenMax, Power3 } from 'gsap';

// Utils.
import { copySplitAnimateIn, copySplitAnimateOut } from 'utils/animations';

/**
* Animation handler for the Persona section
*
* @param {Object} refs A key-DOM dictionary to pass the references of components.
* @param {Function} callback The callback function after animations completed.
*/
export const onAnimateIn = (refs, callback = () => {}) => {
    const inDelay = 4.5;

    Promise.all([
        onHeaderAnimateIn(refs.header, inDelay),
        copySplitAnimateIn(refs.description, inDelay + 0.35),
        onLoaderAnimateIn(refs.loader, inDelay + 0.95),
        onLoaderAnimateCopyIn(refs.loaderCopy, inDelay + 0.95),
    ]).then(callback);
};
/**
* Animation handler for the Persona section
*
* @param {Object} refs A key-DOM dictionary to pass the references of components.
* @param {Function} callback The callback function after animations completed.
*/
export const onAnimateOut = (refs, callback = () => {}) => {
    Promise.all([
        onHeaderAnimateOut(refs.header),
        copySplitAnimateOut(refs.description, 0.25),
        onLoaderAnimateOut(refs.loader, 0.75),
    ]).then(() => {
        callback()
    });
};

const onHeaderAnimateIn = (ref, delay = 0) => (
    new Promise(resolve => {
        TweenMax.fromTo(ref, 3,
            { autoAlpha: 0, y: 100 },
            { autoAlpha: 1, y: 0, ease: Power3.easeOut, delay, onComplete: () => resolve(true) },
        );
    })
);

const onHeaderAnimateOut = (ref, delay = 0) => (
    new Promise(resolve => {
        TweenMax.to(ref, 1.5,
            { autoAlpha: 0, y: -100, delay, ease: Power3.easeIn, onComplete: () => resolve(true) },
        );
    })
);

const onLoaderAnimateIn = (ref, delay = 0) => (
    new Promise(resolve => {
        TweenMax.fromTo(ref, 3,
            { autoAlpha: 0, y: 100 },
            { autoAlpha: 1, y: 0, ease: Power3.easeOut, delay, onComplete: () => resolve(true) },
        );
    })
);

const onLoaderAnimateCopyIn = (ref, delay = 0) => (
    new Promise(resolve => {
        TweenMax.fromTo(ref, 3,
            { autoAlpha: 0 },
            { autoAlpha: 1, ease: Power3.easeOut, delay, onComplete: () => resolve(true) },
        );
    })
);

const onLoaderAnimateOut = (ref, delay = 0) => (
    new Promise(resolve => {
        TweenMax.to(ref, 1.5,
            { autoAlpha: 0, y: -100, delay, ease: Power3.easeIn, onComplete: () => resolve(true) },
        );
    })
);
