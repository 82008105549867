import { TweenMax, Expo } from "gsap";

/**
* Animation handler for the StyleBoard section
*
* @param {Object} refs A key-DOM dictionary to pass the references of child components.
* @param {Function} callback The callback function after animations completed.
*/
export const onAnimateIn = (refs, callback = () => {}) => {
    TweenMax.set(refs.scrollerP, { autoAlpha: 0 });

    Promise.all([
        onHeaderAnimateIn(refs.header),
        refs.pinboard.onAnimateIn(),
        scrollbarAnimateIn(refs),
    ]).then(callback);
};

const onHeaderAnimateIn = ref => (
    new Promise(resolve => {
        TweenMax.fromTo(ref, 1.5,
            { autoAlpha: 0, y: 50 },
            { autoAlpha: 1, y: 0, onComplete: () => resolve(true) },
        );
    })
);

const scrollbarAnimateIn = refs => (
    new Promise(resolve => {
        TweenMax.fromTo(refs.progressBar, 3,
            { scaleX: 0, },
            { scaleX: 1, transformOrigin: 'left', delay: 0.5, ease: Expo.easeInOut },
        );
        TweenMax.fromTo(refs.scrollerP, 1.5,
            { autoAlpha: 0 },
            { autoAlpha: 1, delay: 2.25, onComplete: () => resolve(true) },
        );
    })
);
