// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Components.
import Button from 'components/Button';

// Utils.
import { getLocalization, localize } from 'utils/localization';

// Styles.
import styles from './PinboardClickTile.module.scss';

const PinboardClickTile = ({ onClick }) => (
    <div className={ `${ styles.PinboardClickTile } ${ styles[getLocalization()] }` }>
        <div>{ localize('STYLEBOARD_PAGE_TAKE_THE_BOARD') }</div>
        { onClick &&
            <Button className={ styles.button } onClick={ onClick } hideArrow>
                <div className={ styles.buttonContent }>
                    { localize('STYLEBOARD_PAGE_SCAN_PINCODE') }
                </div>
            </Button>
        }
    </div>
);

PinboardClickTile.propTypes = {
    onClick: PropTypes.func,
};

PinboardClickTile.defaultProps = {
    onClick: null,
};

export default PinboardClickTile;
