import personas from 'json/persona.config';

/**
* Retrives the list of available persona by keys.
*
* @return {Array} List of persona keys.
*/
export const getPersonas = () => {
    return Object.keys(personas);
};

/**
* Retrieves the localiztion key of the persona description.
*
* @param {String} key Key of the persona to retrieve data for.
* @return {?String} Localization key of the persona description.
*/
export const getPersonaDescription = key => {
    return personas[key].description || null;
};

/**
* Retrieves the image paths to use for the persona board.
*
* @param {String} key Key of the persona to retrieve data for.
* @return {?Array} Array of the image paths for the persona board.
*/
export const getPersonaBoard = key => {
    return personas[key].board || null;
};

/**
* Retrieves the url for the Pinterest board.
*
* @param {String} key Key of the persona to retrieve data for.
* @return {?String} URL to the Pinterest board.
*/
export const getPersonaBoardURL = key => {
    return personas[key].board_url || null;
};

/**
* Retrieves the source path of the persona isolated image.
*
* @param {String} key Key of the persona to retrieve image for.
* @return {?String} Source path to the persona isolated image.
*/
export const getPersonaIsolatedImage = key => {
    return personas[key].isolated_image || null;
};

/**
* Retrieves the source path of the persona video.
*
* @param {String} key Key of the persona to retrieve data for.
* @return {?String} Source path to the persona video.
*/
export const getPersonaVideo = key => {
    return personas[key].persona_video || null;
};

/**
* Retrieves the localization key of the persona pincode video.
*
* @param {String} key Key of the persona to retrieve data for.
* @return {?String} Localization key of the persona pincode video.
*/
export const getPersonaPincodeVideoKey = key => {
    return personas[key].pincode_video_key || null;
};
