import React, { useState } from 'react';
import PropTypes from 'prop-types'

// Components.
import CountryCodeSelector from 'components/CountryCodeSelector';
import LinkForm from 'components/LinkForm';

// Utils.
import { isValidPhoneNumber } from 'react-phone-number-input';

// Styles.
import styles from './SmsForm.module.scss';

const SmsForm = ( props ) => {
    const [ countryCode, setCountryCode ] = useState(null);

    const validateBeforeSubmit = () => {
        const number = getFormattedNumber();
        if (isValidPhoneNumber(number)) return { statusCode: 200 }
        else return { statusCode: 401, errorMessage: true }
    };

    const handleCountryCodeChange = value => {
        setCountryCode(value);
    };

    const handleOnChange = event => {
        props.setSendTo(event.target.value);
    };

    const getFormattedNumber = () => {
        const number = `${ countryCode }${ props.sendTo }`;
        const formatted = number.replace(/[^a-z0-9\\+]/g, '');
        return formatted;
    };

    return (
        <LinkForm
            communicationType='SMS'
            sendTo={ getFormattedNumber() }
            validate={ validateBeforeSubmit }
            privacyModalActive={ props.privacy }
            pinterestUrl={ props.pinterestUrl }
            reset={ reset => { if (reset) props.setSendTo( '' ); } }
        >
            <div className={ styles.SmsForm }>
                <div className={ styles.countryCode }>
                    <CountryCodeSelector defaultValue='US' onChange={ handleCountryCodeChange } />
                </div>
                <input
                    id={ props.communicationType }
                    name={ props.communicationType }
                    type='tel'
                    className={ styles.input }
                    required
                    value={ props.sendTo }
                    autoComplete='off'
                    onChange={ handleOnChange }
                    onFocus={ props.onKeyboardOpen }
                />
            </div>
        </LinkForm>
    );
};


SmsForm.propTypes = {
    communicationType: PropTypes.string,
    pinterestUrl: PropTypes.string,
    placeholder: PropTypes.string,
    privacy: PropTypes.func,
    setSendTo: PropTypes.func,
    sendTo: PropTypes.string,
}

SmsForm.defaultProps = {
    communicationType: 'SMS',
    pinterestUrl: 'http://www.pinterest.com',
    placeholder: '+X (XXX) XXX-XXXX',
    privacy: null,
    setSendTo: null,
    sendTo: '',
}

export default SmsForm;
