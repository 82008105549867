import React, { useCallback, useEffect, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles
import "react-simple-keyboard/build/css/index.css";
import styles from './OnScreenKeyboard.module.scss'

const KeyboardLayout = {
    DEFAULT: 'DEFAULT',
    SHIFT: 'SHIFT',
    NUMBER: 'NUMBER',
};

const KeyboardConfig = {
    [KeyboardLayout.DEFAULT]: [
        '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
        '{tab} q w e r t y u i o p [ ] \\',
        '{lock} a s d f g h j k l ; \' {enter}',
        '{shift} z x c v b n m , . / {shift}',
        '.com {space} @',
    ],
    [KeyboardLayout.SHIFT]: [
        '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
        '{tab} Q W E R T Y U I O P { } |',
        '{lock} A S D F G H J K L : " {enter}',
        '{shift} Z X C V B N M < > ? {shift}',
        '.com {space} @',
    ],
    [KeyboardLayout.NUMBER]: [
        '1 2 3',
        '4 5 6',
        '7 8 9',
        '{bksp} 0 {enter}',
    ],
};

const OnScreenKeyboard = ({ inputName, onChange, onClose, value }) => {
    const containerRef = useRef(null);
    const keyboardRef = useRef(null);

    const [ keyboardLayout, setKeyboardLayout ] = useState(KeyboardLayout.DEFAULT);

    const handleShift = useCallback(() => {
        const newLayoutName = keyboardLayout === KeyboardLayout.DEFAULT
            ? KeyboardLayout.SHIFT : KeyboardLayout.DEFAULT;

        setKeyboardLayout(newLayoutName);
    }, [ keyboardLayout ]);

    const onKeyboardPress = useCallback(button => {
        switch (button) {
            case '{enter}':
                onClose();
                break;

            case '{lock}':
            case '{shift}':
                handleShift();
                break;

            default:
                break;
        }
    }, [ handleShift, onClose ]);

    useEffect(() => {
        return () => keyboardRef.current.clearInput();
    }, []);

    const handleOffscreenClick = useCallback(event => {
        const isInput = event.target.tagName.toLowerCase() === 'input';
        const isKeyboard = event.y >= containerRef.current.getBoundingClientRect().y;
        if (!isInput && !isKeyboard) onClose();
    }, [ onClose ]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.addEventListener('click', handleOffscreenClick);
        }, 1000);

        return () => {
            if (timeout) clearInterval(timeout);
            window.removeEventListener('click', handleOffscreenClick);
        };
    }, [ handleOffscreenClick, onClose ]);

    useEffect(() => {
        keyboardRef.current.setInput(value);
    }, [ value ]);

    const classes = classnames({
        [styles.keyboard]: true,
        [styles.number]: KeyboardLayout.NUMBER,
    });

    return (
        <div className={ styles.OnScreenKeyboard } ref={ containerRef }>
            <Keyboard
                theme={ classes }
                keyboardRef={ r => keyboardRef.current = r }
                layout={ KeyboardConfig }
                layoutName={ inputName === 'SMS' ? KeyboardLayout.NUMBER : keyboardLayout }
                onChange={ onChange }
                onKeyPress={ button => onKeyboardPress(button) }
            />
        </div>
    );
};

OnScreenKeyboard.propTypes = {
    inputName: PropTypes.string,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    value: PropTypes.string,
};

OnScreenKeyboard.defaultProps = {
    inputName: null,
    onChange: null,
    onClose: null,
    value: null,
};

export default OnScreenKeyboard;
