import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Components
import ExitButton from 'components/ExitButton';
import OnScreenKeyboard from 'components/OnScreenKeyboard';

// Elements
import PinboardLinkForm from './elements/PinboardLinkForm';
import ExitModal from './elements/ExitModal';
import TimeoutModal from './elements/TimeoutModal';
import AgeGateModal from './elements/AgeGateModal';

// Utils
import GoogleAnalytics from 'utils/analytics';
import { QuizContext } from 'utils/context';
import { ModalTypes } from 'utils/constants';

import styles from './Modal.module.scss';

const Modal = ({ type, children, timeout, fadeOut }) => {
    const context = useContext( QuizContext );
    const [ hideExitButton, setHideExitButton ] = useState(false);
    const [ keyboardInputName, setKeyboardInputName ] = useState(null);
    const [ keyboardValue, setKeyboardValue ] = useState(null);

    const handleCloseModal = () => {
        GoogleAnalytics.button.click(`Modal Exit Button`);
        context.closeModal();
    };

    const handleKeyboardOpen = (name, value) => {
        setKeyboardValue(value);
        setKeyboardInputName(name);
    };

    const handleKeyboardClose = () => {
        setKeyboardInputName(null);
        setKeyboardValue(null);
    };

    const handleKeyboardInputChange = input => {
        setKeyboardValue(input);
    };

    const handlePhysicalKeyboardInputChange = input => {
        setKeyboardValue(input);
    };

    const renderModalData = ( type ) => {
        switch ( type ) {
            case ModalTypes.PINBOARD_LINK_FORM: {
                return !context.ageGate ? <AgeGateModal /> :
                    <PinboardLinkForm
                        hideExit={ setHideExitButton }
                        keyboardInput={ keyboardValue }
                        onKeyboardOpen={ handleKeyboardOpen }
                        onKeyboardClose={ handleKeyboardClose }
                        onPhysicalKeyboardChange={ handlePhysicalKeyboardInputChange }
                    />
            }

            case ModalTypes.EXIT_MODAL: {
                if (!hideExitButton) setHideExitButton( true );
                return <ExitModal />
            }

            case ModalTypes.TIMEOUT_MODAL: {
                if (timeout) {
                    if (!hideExitButton) setHideExitButton( true );
                    return <TimeoutModal />
                }
                break;
            }

            default:
                return;
        }
    }

    return (
        <div className={ classnames( { [ styles.Modal ]: true, [ styles.fadeOut ]: fadeOut }) }>
            <div className={ classnames({ [ styles.innerWrapper ]: true, [styles.openKeyboard]: keyboardInputName !== null }) }>
                { !hideExitButton &&
                    <div className={ styles.closeBtn }>
                        <ExitButton onClick={ handleCloseModal } />
                    </div>
                }
                { renderModalData( type ) }
                { children }
            </div>
            { keyboardInputName !== null &&
                <div className={ styles.keyboard }>
                    <OnScreenKeyboard
                        inputName={ keyboardInputName }
                        onChange={ handleKeyboardInputChange }
                        onClose={ handleKeyboardClose }
                        value={ keyboardValue }
                    />
                </div>
            }
        </div>
    );
};

Modal.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node,
    timeout: PropTypes.bool,
    className: PropTypes.string,
};

Modal.defaultProps = {
    type: null,
    children: null,
    timeout: false,
}

export default Modal;
