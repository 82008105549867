// Modules.
import React from 'react';

// Styles.
import styles from './LogoJCPenney.module.scss';

const LogoJCPenney = () => (
    <img className={ styles.LogoJCPenney } src="/images/logo-jcpenney.svg" alt="JCPenney Logo" />
);

export default LogoJCPenney;
