// Modules.
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import TweenMax, { Power3 } from 'gsap';

// Styles.
import styles from './ExitButton.module.scss';

// Utils
import { localize } from 'utils/localization';
import useTransitions from 'hooks/useTransitions';

const ExitButton = React.forwardRef(({ onClick }, animRef) => {

    const node = useRef(null);

    const onAnimateIn = (delay = null) => {
        return new Promise(resolve => {
            TweenMax.fromTo(node.current, 1.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
        });
    };

    const onAnimateOut = (delay = null) => {
        return new Promise(resolve => {
            TweenMax.to(node.current, 1.5, { autoAlpha: 0, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
        });
    };

    if (animRef) {
        useTransitions(animRef, f => f, onAnimateIn, onAnimateOut);
    }

    return (
        <div className={ styles.ExitButton } onClick={ onClick } ref={ node }>
            <div className={ styles.label }> { localize('CORE_EXIT_BUTTON_LABEL')} </div>
            <div className={ styles.icon }>
                <div className={ styles.cross } />
            </div>
        </div>
    );

});

ExitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default ExitButton;
