// Modules.
import React from 'react';

// Utils.
import { localize } from 'utils/localization';
import { getPersonaIsolatedImage } from 'utils/persona';

// Styles.
import styles from './PersonaTitle.module.scss';

const PersonaTitle = ({ persona }) => (
    <div className={ styles.PersonaTitle }>
        <div className={ styles.image } style={ { backgroundImage: `url(${ getPersonaIsolatedImage(persona) })` } } />
        <div className={ styles.header }> { `${ localize('PERSONA_PAGE_DISCOVER') } ${ localize(persona) }` }</div>
    </div>
);

export default PersonaTitle;
