import React from 'react';

export default {
    "privacy": 
        <>
            <h1>Thinkingbox Privacy Statement:</h1>
            <p>Thinkingbox Media & Design Inc. (Thinkingbox) has created the jcpstylequiz.com experience. Thinkingbox does not collect, process or store user data. In order to send a link to a Pinterest Board via mobile SMS or email, Thinkingbox uses backend services Twilio and SendGrid. See below for extracts from the privacy policies from both entities in relation to the specific collection of data through the jcpstylequiz.com service.</p>
            <p><strong>How Twilio Processes Your Personal Information</strong></p>
            <p>Your personal information typically shows up on Twilio’s platform in a few different ways:</p>
            <ul>
                <li>Communications-related personal information about your end users, like your end users’ phone numbers for number-based communications, IP addresses for IP-based communications, or device tokens for push notifications, show up in our systems when you use or intend to use this information to contact your end user through use of our products and services.</li>
                <li>Your end users’ personal information may show up in “friendly names,” which are strings you provide, if you choose to include your end users’ personal information as part of a string.</li>
                <li>Your end users’ personal information may also be contained in the content of communications you (or your end users) send or receive using Twilio’s products and services.</li>
            </ul>
            <p>We call the information in the first two bullets above Customer Usage Data. The information in the third bullet is what we refer to as Customer Content.</p>
            <p>As noted above, data protection (aka privacy) law in certain jurisdictions, like the EEA, differentiate between “controllers” and “processors” of personal information. When Twilio processes Customer Content, we generally act as a processor. When we process Customer Usage Data, we act as a processor in many respects, but we may act as a controller in others. For example, we may need to use certain Customer Usage Data for the legitimate interests of billing, reconciling invoices with telecommunications carriers, and in the context of troubleshooting and detecting problems with the network.</p>
            <hr />
            <p><strong>What Customer Usage Data and Customer Content Twilio Processes and Why</strong></p>
            <p>We use Customer Usage Data and Customer Content to provide services to you and to carry out necessary functions of our business as a communications service provider. We do not sell your end users’ personal information and we do not share your end users’ information with third parties for those third parties’ own business interests.</p>
            <p>For more information see https://www.twilio.com/legal/privacy</p>
            <p></p>
            <p>SendGrid Policy Version Date: April 25, 2018</p>
            <p>SendGrid, Inc. and its group of companies ("SendGrid", "Us", "Our", or "We") are committed to protecting Your privacy. This Services Privacy Policy sets out how We collect and process personal information through Our email delivery platform and related services including the development, transmission, analysis, and management of email messages and other digital communications and tools (the "Services") and generally in the course of doing business – such as when an individual who has registered to use the Services ("Customer"), potential Customer, or other third party engages with Us in relation to the provision of the Services (collectively "You"). Please note that for the purposes of EU data protection legislation, SendGrid is the controller of Your personal information.</p>
            <p>This Services Privacy Policy (this "Policy") does not cover how SendGrid collects and uses personal information collected from individuals who visit Our website ("Visitors"), as described in Our separate Website Privacy Policy.</p>
            <hr />
            <ol>
                <li>
                    <p><strong>Information Collected Through the Services</strong></p>
                    <p>Through Our Services, SendGrid collects certain personal information, as follows:</p>
                    <ul>
                        <li>
                            <p>Information Customers Provide to Us: You may provide personal information to Us through the Services – for example, when You create a SendGrid account, use the Services to send emails or other communications, contact customer support, send Us an email, or communicate with Us in any other way.When setting up a SendGrid account, You will be asked to provide certain basic information such as a name, email address, username, password, company name, occupation, location and phone number (e.g. if You are using two-factor authentication). If You purchase one of Our paid plans, You may also need to provide Us with payment and billing information such as the Customer’s credit card details and billing address.</p>
                            <p>As a Customer, SendGrid will also maintain a record of Your purchases, transactional information, Services history and usage, and any communications and responses.</p>
                            <p>We may also collect personal information, such as Your contact information, job details and feedback, when You attend Our events, take part in surveys, or through other business or marketing interactions We may have with You.</p>
                        </li>
                        <li>
                            <p>Information We Collect Automatically Through the Services: When You use the Services, We may collect certain information automatically about Your device and Your use of the Services. This will include IP addresses, browser types, log files, and other information regarding Your system and connection. We collect information about how You access and use the Services, such as what pages are viewed and what portions of the Services are used. We also collection information regarding the performance of the Services, including metrics related to the deliverability of emails and other electronic communications You send through the SendGrid platform. This information allows Us to improve the content and operation of the Services, and facilitate research and analysis of the Services. Please be aware that We use web beacons in Our emails to recipients delivered using the SendGrid platform. These web beacons track certain behavior such as whether the email sent through the SendGrid platform was delivered, opened, clicked on, whether it bounced or was treated as spam. This allows Us to measure the performance of Our Customer's email campaigns, and to provide analytics information and enhance the effectiveness of Our Services.We collect this information automatically through the use of various commonly used information-gathering technologies including cookies and web beacons, to collect information as users and Visitors navigate the Website and use the Services ("Web Site Navigational Information"). We use these technologies to analyze trends, administer Web sites and Services, track users’ and Visitors’ movements around Our Website and Services, serve targeted advertisements and gather demographic information about Our user and Visitor base as a whole. Additional information about Our use of Web Site Navigational Information is available at https://sendgrid.com/policies/privacy/website-navigational-information/.</p>
                        </li>
                        <li>
                            <p>Information We Process on Behalf of Our Customers: SendGrid Customers may also input or upload certain personal information to the SendGrid platform which We will process as a data processor on Our Customers' behalf. For example, when Our Customers use Our email delivery platform to upload contact information relating to their email recipients, other personal information about recipients, or upload contents of email communications, or create marketing campaigns, We will act as a data processor and only process such information on Our Customer's behalf and in accordance with their instructions (as defined in Our Customer agreements).We will use such personal information to: provide and deliver the Services to the Customer, prevent or address any service or technical issues, respond to a Customer's request, instructions or support request, or for any other purpose provided for in the Customer agreement, or in accordance with or as may be required by law.</p>
                            <p>In such cases, it is the Customer (or, as the case may be, other third parties) who remain responsible for the handling of the personal information and with compliance with any applicable data privacy laws. If You have questions or concerns about how such personal information is handled, You should contact the relevant entity that is using the Services, and refer to their separate privacy policies.</p>
                        </li>
                        <li>
                            <p>Information We Collect from Other Sources: From time to time, We may receive personal information about You from third-party sources (including public databases, Our joint marketing partners, and social media platforms), but only where We have checked that these third-parties either have Your consent or are otherwise legally permitted or required to disclose Your personal information to Us. The types of personal information We collect from third-parties include name, title, company name, job function, expertise, telephone number, or email address and We use the information We receive from these third-parties to improve the Services, the accuracy of the records we hold about You, and relevant marketing and other offers.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p><strong>Use of Information</strong></p>
                    <p>We may use the information We collect through the Services for a range of reasons, which include:</p>
                    <ul>
                        <li>To provide, operate, optimize and maintain the Services;</li>
                        <li>To deal with Customer enquiries and support requests, and to provide information and access to resources or services that they have requested from Us;</li>
                        <li>To manage the SendGrid platform, system administration and security;</li>
                        <li>To process and complete transactions;</li>
                        <li>To compile aggregated statistics about the operation and use of the Services and to better understand the preferences of Our Customers;</li>
                        <li>To send Our Customers technical alerts, reports, updates, security notifications and other Service-related communications;</li>
                        <li>To carry out research and development to improve Our products and services;</li>
                        <li>To send You marketing communications (where this is in accordance with Your communication preferences);</li>
                        <li>Investigate and prevent fraud, unauthorized access or use of Services, breaches of terms and policies, and other wrongful behavior;</li>
                        <li>To carry out other legitimate business purposes, as well as other lawful purposes about which We will notify You.</li>
                    </ul>
                </li>
                <li>
                    <p><strong>Legal basis for processing (EEA individuals only)</strong></p>
                    <p></p>
                    <p>If You are from the European Economic Area ("EEA"), Our legal basis for collecting and using the personal information described above will depend on the personal information concerned and the specific context in which We collect it.</p>
                    <p>However, We will normally collect personal information from You only where We have Your consent to do so, where We need the personal information to perform a contract with You, or where the processing is in Our legitimate interests and not overridden by Your data protection interests or fundamental rights and freedoms. In some cases, We may also have a legal obligation to collect the personal information in question.</p>
                    <p>If We ask You to provide personal information to comply with a legal requirement or to perform a contract with You, we will make this clear at the relevant time and advise You whether the provision of Your personal information is mandatory or not (as well as of the possible consequences if You do not provide Your personal information).</p>
                    <p>Similarly, if We collect and use Your personal information in reliance on Our or a third party's legitimate interests and those interests are not already listed above (in the "Use of Information" section), We will make clear to You at the relevant time what those legitimate interests are.</p>
                    <p>If You have questions about or need further information concerning the legal basis on which We collect and use Your personal information, please contact Us using the contact details provided in Section 14 below.</p>
                </li>
                <li>
                    <p><strong>Information We Share</strong></p>
                    <p>We may share and disclose Your personal information to third parties in the following circumstances:</p>
                    <ul>
                        <li>Within the SendGrid group of companies for the purposes outlined in this Policy.</li>
                        <li>To vendors and other third-party service providers who require access to Your personal information to assist in the provision of the Services, and other business-related functions. For example, We use various Sub-processors in provision of our services.</li>
                        <li>To partners and sponsors: Certain parts of the Services may be offered in connection with other companies who are partners or sponsors. When You use these types of Services, We will ensure that the identity of those partners or sponsors is clearly visible and that You have an opportunity not to proceed or to opt out. Such information will be handled in accordance with their own privacy policies and if You have questions, You should contact the partner or sponsor directly.</li>
                        <li>We may also disclose your information to third parties: (a) where required by law or regulatory requirement, court order or other judicial authorization, (b) in response to lawful requests by public authorities, including for the purposes of meeting national security and law enforcement requirements; (c) in connection with the sale, transfer, merger, bankruptcy, restructuring or other reorganization of a business; (d) to protect or defend Our rights, interests or property, or that of third parties; (e) to investigate any wrongdoing in connection with the Website or Our services; or (f) to protect the vital interests of an individual.</li>
                        <li>With Your consent.</li>
                    </ul>
                </li>
                <li>
                    <p>We may also share personal information collected through the Services with third party companies so that they may offer products and services that We believe will be of interest to You. We may also share personal information with third party advertising-serving companies to better target the adverts and other content displayed on Our site and to provide offers We think may be of interest to You. You may opt-out of disclosure of Your information to third parties for direct marketing purposes – see the Choice and Opt-Out section below. In addition, third party advertisers may receive certain personal information about You if you click on advertisements displayed on Our Website or Services. If You do not wish these third parties to process Your personal information, please do not click on these adverts.</p>
                </li>
                <li>
                    <p><strong>Third Party Sites</strong></p>
                    <p>Our Services may contain links to other websites and services which are outside Our control and are not covered by this Policy. For example, We may partner with other companies so that You can integrate SendGrid with other third party accounts. While We try to link only to Services that share Our standards for privacy, We are not responsible for the content, security or privacy practices employed by other sites. If You access other websites or services using the links provided, the operators of these websites and services may collect information from You which will be used by them in accordance with their privacy policy, which may differ from Ours.</p>
                </li>
                <li>
                    <p><strong>Our Security</strong></p>
                    <p>We maintain reasonable and appropriate security measures to protect Your personal information from loss, misuse, and unauthorized access, disclosure, alteration and destruction. We will take all reasonable steps to ensure the safety of Your personal information. For further information about Our security practices, please see https://sendgrid.com/policies/security/.</p>
                </li>
                <li>
                    <p><strong>Sensitive Data</strong></p>
                    <p>SendGrid does not knowingly solicit or collect, and You should not provide, any information regarding an individual’s medical or health condition, race or ethnic origin, political opinions, religious or philosophical beliefs or other sensitive data, as such term is defined in the SendGrid Terms of Service.</p>
                </li>
                <li>
                    <p><strong>Information about International Transfers</strong></p>
                    <p></p>
                    <p>Your personal information may be stored and processed by Us in any country where We have facilities or where We engage service providers, and by reading this Policy and using the Services You acknowledge that Your personal information may be transferred to countries outside of Your country of residence, including the United States, which may have different data protection rules to those of Your country. Where We do so, Our collection, storage and use of Your personal information will be in accordance with the purposes set out in this Policy.</p>
                    <p>For personal information We receive from the EEA and Switzerland, SendGrid, Inc. participates and complies with the EU-US and Swiss-US Privacy Shield Framework as set forth by the US Department of Commerce. You can find out more about Our commitment to the Privacy Shield Principles in Our Notice of Privacy Shield Certification. If there is any conflict between the terms of this Policy and the Privacy Shield Principles, the Privacy Shield Principles shall govern.</p>
                    <p>We will ensure that where other non-EEA affiliates or third party service providers have access to personal information outside of the EEA, that they too commit to provide the same level of protection as the Privacy Shield Principles.</p>
                    <p>If Your use of the Services requires SendGrid to process personal data falling within the scope of GDPR, SendGrid’s Data Processing Addendum is available for e-signature here. Additionally, more information about EU data protection laws is available by visiting https://sendgrid.com/resource/general-data-protection-regulation/.</p>
                </li>
                <li>
                    <p><strong>Children</strong></p>
                    <p>The Services are not directed at children under the age of 16. We do not knowingly solicit or collect personal information from children under the age of 16.</p>
                </li>
                <li>
                    <p><strong>Your Choices and Opt-Outs</strong></p>
                    <p>You can opt out of receiving marketing emails from Us at any time, or opt out from having Your contact information shared with third parties for direct marketing purposes. You can opt out of Our marketing emails by clicking the 'unsubscribe' link at the bottom of Our marketing messages.Also, all opt out requests can be made by emailing Us at datasubjectrequests@sendgrid.com.</p>
                    <p>Please note that it may take up to 3 days to remove Your contact information from Our marketing communications lists, so You may receive correspondence from Us for a short time after You make Your request.Please note that some communications (such as service messages, account notifications, billing information) are considered transactional and necessary for account management and You cannot opt out of these messages unless You request to deactivate Your SendGrid account.</p>
                </li>
                <li>
                    <p><strong>Other Data Rights</strong></p>
                    <p>SendGrid takes reasonable steps to ensure that the data We collect is reliable for its intended use, accurate, complete and up to date. You can manage many of Your account and profile settings within the dashboard provided through the SendGrid platform. However, if You want to contact Us directly about accessing, changing or deleting Your personal information, or altering Your data or marketing preferences, please email Us at datasubjectrequests@sendgrid.com. We will consider Your request in accordance with applicable laws.If You are from certain territories (such as the European Union), You may have additional data protection rights, such as broader rights to access and deleted Your personal information, as well as rights to:</p>
                    <ul>
                        <li>Restriction: You can ask Us to restrict processing Your information in certain circumstances;</li>
                        <li>Objection: You can object to further processing Your personal information in certain circumstances;</li>
                        <li>Withdrawal of consent: Where We rely on Your consent to process Your personal information, You have the right to decline consent and/or if provided, to withdraw it at any time. This will not affect the lawfulness of processing prior to the withdrawal of Your consent.</li>
                        <li>Portability: You can ask Us to provide Your information in a structured, commonly use and machine-readable form in certain circumstances.</li>
                    </ul>
                </li>
                <li>
                    <p>If You would like to exercise the above rights, please write to Us using the contact details in Section 14 below and We will consider Your request in accordance with applicable data protection laws. We may ask You to verify Your identity in order to help Us respond to Your request.</p>
                    <p>You also have the right to raise questions or complaints with Your data protection authority or the Information Commissioner's Office in the United Kingdom at any time.</p>
                    <p>Information Commissioner's Office Wycliffe House Water Lane Wilmslow Cheshire SK9 5AF</p>
                    <p>Phone: 0303 123 1113 Fax: 01625 524 510 Email: casework@ico.org.uk</p>
                    <p>Please note that if We are processing Your personal information on behalf of Our Customers as a data processor (see Section 1(c) above "Information We Process on Behalf of Our Customers"), then You should direct any data rights requests to Our Customer who holds the SendGrid account. We will not be able to respond directly to such requests and will refer any such requests to the relevant Customer.</p>
                </li>
                <li>
                    <p><strong>Your California Privacy Rights</strong></p>
                    <p></p>
                    <p>California law provides that California residents have a right to request that businesses tell them how their personally identifiable information has been shared with third parties for those third parties’ direct marketing purposes. However, there is an exception to this requirement for businesses that have adopted and disclosed, in their privacy policy, a policy of not disclosing a person’s personally identifiable information to third parties for those third parties’ direct marketing purposes if that person has exercised an option to opt-out of the disclosure of their personally identifiable information to third parties for such purposes.SendGrid has adopted a policy of allowing You to opt-out of the disclosure of Your personally identifiable information to third parties for their direct marketing purposes and thus falls within this exception. Instructions for opting out can be found in the section entitled found in the Choice and Opt-Out section above.</p>
                </li>
                <li>
                    <p><strong>Retention of Your Personal Information</strong></p>
                    <p>We will retain your personal information for the period necessary to fulfil the purposes outlined in this Policy unless a longer retention period is required or permitted by law, for legal, tax or regulatory reasons, or other lawful purposes. Where We have no ongoing legitimate business need to process Your personal information, We will either delete or anonymize it or, if this is not possible (for example, because Your personal information has been stored in backup archives), then We will securely store Your personal information and isolate it from any further processing until deletion is possible.</p>
                    <p>Any personal information that We process on behalf of Our Customers is retained in accordance with the timeframes set out in the relevant Customer agreements.</p>
                </li>
                <li>
                    <p><strong>Updates to this Policy</strong></p>
                    <p>We reserve the right to change or update this Policy from time to time. If material changes are made, We will place a prominent notice on Our Website or Services for at least 30 days prior to the change taking effect, or communicate with You directly by email or through the Services, and will update the last revised date at the top of this Policy. We encourage You to regularly check back on this page to ensure You are up to date with any changes.</p>
                </li>
                <li>
                    <p><strong>How to Contact Us</strong></p>
                    <p>If You have any questions or concerns regarding the use or disclosure of Your personal information through the Services, You can contact Us by sending an email to datasubjectrequests@sendgrid.com, Our Data Protection Officer by sending an email to dpo@sendgrid.com, or by mail at either of the addresses below:</p>
                    <p>SendGrid, Inc. 1801 California St., Suite 500 Denver, CO 80202, U.S.A.</p>
                    <p>SendGrid UK Limited 6th Floor One London Wall London EC2Y 5EB, UK</p>
                </li>
            </ol>        
            <p>For more information see https://sendgrid.com/policies/privacy/services-privacy-policy/</p>
        </>
};