export const actions = {
    INITIALIZE_SCOREBOARD: 'INITIALIZE_SCOREBOARD',
    RESET_SCOREBOARD: 'RESET_SCOREBOARD',
    UPDATE_SCOREBOARD: 'UPDATE_SCOREBOARD',
    TRIGGER_MODAL: 'TRIGGER_MODAL',
    SET_IDLE_TIMER: 'SET_IDLE_TIMER',
    SET_AGE_GATE: 'SET_AGE_GATE',
};

export const initialState = {
    scoreboard: null,
    modal: null,
    idleTimer: null,
};

export const reducer = (state, action) => {
    switch (action.type) {
        case actions.INITIALIZE_SCOREBOARD:
        return {
            ...state,
            scoreboard: { ...action.scoreboard }
        };

        case actions.RESET_SCOREBOARD:
        const nextScoreboard = { ...state.scoreboard };
        Object.keys(nextScoreboard).forEach(key => {
            nextScoreboard[key] = 0;
        });
        return {
            ...state,
            scoreboard: nextScoreboard,
        };

        case actions.UPDATE_SCOREBOARD: {
            const nextScoreboard = { ...state.scoreboard };
            action.points.forEach(key => {
                nextScoreboard[key] = nextScoreboard[key] + 1;
            });
            return { ...state, scoreboard: nextScoreboard };
        }

        case actions.TRIGGER_MODAL: {
            return {
                ...state,
                modal: action.modal,

            };
        }

        case actions.SET_IDLE_TIMER: {
            return {
                ...state,
                idleTimer: action.idleTimer,
            }
        }

        case actions.SET_AGE_GATE: {
            return {
                ...state,
                ageGate: action.ageGate,
            }
        }

        default:
            return { ...state };
    }
};
