// Modules.
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import classnames from 'classnames';
import debounce from 'lodash.debounce';
import throttle from 'lodash.throttle';

// Sections.
import HomeSection from 'sections/HomeSection';
import PersonaSection from 'sections/PersonaSection';
import QuestionSection from 'sections/QuestionSection';
import ResetSection from 'sections/ResetSection';
import Test from 'sections/Test';
import StyleBoardSection from 'sections/StyleBoardSection';

// Components.
import FooterBar from 'components/FooterBar';
import HeaderBar from 'components/HeaderBar';
import Modal from 'components/Modal';
// import Scoreboard from 'components/Scoreboard';

// Utils.
import { actions, initialState, reducer } from './App.reducer';
import { QuizContext } from 'utils/context';
import { getPersonas } from 'utils/persona';
import { ModalTypes } from 'utils/constants';
import IdleManager from 'utils/managers/IdleManager';

// Styles.
import styles from './App.module.scss';

const App = () => {
    const [ state, dispatch ] = useReducer(reducer, initialState);

    // Set the Timeout to start
    const [ isTimedOut, setIsTimedOut ] = useState( false );

    // First, initialize the scoreboard, setting a score of zero for each persona.
    useEffect(() => {
        const scoreboard = {};
        getPersonas().forEach(key => scoreboard[key] = 0);
        dispatch({ scoreboard, type: actions.INITIALIZE_SCOREBOARD });
    }, []);

    // As an added bonus, also keep track of when the window resizes.
    const [ stage, setStage ] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        window.addEventListener('resize',
            debounce(() => {
                setStage({
                    ...stage,
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
            }, 100)
        );
    }, [ stage ]);

    // Named Function for removing EventListener
    const startIdle = useCallback(() => {
        IdleManager.startIdleTimer( () => setIsTimedOut( true ) );
        document.removeEventListener( 'touchend', startIdle );
    }, []);

    const clearIdle = useCallback(() => {
        IdleManager.clearIdleTimer();
        document.addEventListener( 'touchend', startIdle );
    }, [ startIdle ]);

    const startIdleThrottled = useCallback(
        throttle(() => {
            IdleManager.startIdleTimer( () => setIsTimedOut( true ) );
        }, 1000)
    , []);

    useEffect(() => {
        // When idle timer is inititiated:
        // - start the IdleManager timeout
        // - Add Event Listener to track clicks to reset idle timer
        if (state.idleTimer && !isTimedOut) {
            IdleManager.startIdleTimer( () => setIsTimedOut( true ) );
            document.addEventListener( 'touchstart', clearIdle );
            document.addEventListener( 'mousemove', startIdleThrottled );
        }

        // When idle timer has timed out
        // - Remove click Event Listener
        // - Reset idle timer
        // - Open Timeout Modal
        if (isTimedOut) {
            document.removeEventListener( 'touchstart', clearIdle );
            document.removeEventListener( 'mousemove', startIdleThrottled );
            dispatch({ idleTimer: false,  type: actions.SET_IDLE_TIMER });
        }
    }, [ state.idleTimer, isTimedOut, clearIdle, startIdleThrottled]);

    // Then, we can set up the context to pass the information to child components.
    const contextData = {
        width: stage.width,
        height: stage.height,
        scoreboard: state.scoreboard,
        idleTimer: state.idleTimer,
        ageGate: state.ageGate,
        keyboardOpen: state.keyboardOpen,
        onChangeInput: state.onChangeInput,
        inputValue: state.inputValue,
        reset: () => {
            dispatch({ type: actions.RESET_SCOREBOARD });
        },
        update: points => {
            dispatch({ points, type: actions.UPDATE_SCOREBOARD });
        },
        openModal: type => {
            dispatch({ modal: type, type: actions.TRIGGER_MODAL });
        },
        closeModal: () => {
            dispatch({ modal: null, type: actions.TRIGGER_MODAL});
        },
        setTimer: bool => {
            setIsTimedOut( false );
            dispatch({ idleTimer: bool, type: actions.SET_IDLE_TIMER })
        },
        setAgeGate: bool => {
            dispatch({ ageGate: bool, type: actions.SET_AGE_GATE })
        },
    };

    return (
        <QuizContext.Provider value={ contextData }>
            <div className={ styles.App }>
                {/* <Scoreboard /> */}

                <BrowserRouter>
                    <div className={ classnames({
                        [styles.container]: true,
                        [styles.blur]: state.modal || isTimedOut,
                    })}>
                        <HeaderBar />
                        <FooterBar />

                        <Route path='/' component={ HomeSection } exact />
                        <Route path='/question/:index' component={ QuestionSection } exact />
                        <Route path='/persona' component={ PersonaSection } exact />
                        <Route path='/styleboard' component={ StyleBoardSection } exact />
                        <Route path='/reset' component={ ResetSection } exact />
                        <Route path='/test' component={ Test } exact />
                    </div>

                    { state.modal &&
                        <Modal fadeOut={ isTimedOut } type={ state.modal }></Modal>
                    }

                    { isTimedOut &&
                        <Modal type={ ModalTypes.TIMEOUT_MODAL } timeout={ isTimedOut }></Modal>
                    }

                </BrowserRouter>
            </div>
        </QuizContext.Provider>
    );
};

export default App;
