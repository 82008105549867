// Modules.
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useInViewport } from 'react-in-viewport';
import TweenMax from 'gsap';

// Components.
import Button from 'components/Button';

// Utils.
import GoogleAnalytics from 'utils/analytics';
import { ModalTypes } from 'utils/constants';
import { QuizContext } from 'utils/context';
import { getLocalization, localize } from 'utils/localization';
import AudioManager from 'utils/managers/AudioManager';
import { getPersonaPincodeVideoKey } from 'utils/persona';

// Styles.
import styles from './PincodeVideo.module.scss';

const PincodeVideo = ({ persona }) => {
    const ref = useRef(null);
    const buttonRef = useRef(null);
    const replayRef = useRef(null);
    const videoRef = useRef(null);
    const context = useContext(QuizContext);

    const [ videoPlayed, setVideoPlayed ] = useState( false )

    const openModal = () => {
        GoogleAnalytics.button.click(`Style Board Send Link Button`);
        AudioManager.play(AudioManager.state.SOUNDS.PAUSE);
        context.openModal(ModalTypes.PINBOARD_LINK_FORM);
    };

    const onEnterViewport = () => {
        if (videoPlayed) return;

        videoRef.current.play();
        setVideoPlayed(true);
    };

    const onReplay = () => {
        TweenMax.to([buttonRef.current, replayRef.current], 1,
            { y: 50, autoAlpha: 0 },
        );
        TweenMax.to(videoRef.current, 1,
            { y: 50, autoAlpha: 0, onComplete: () => {
                videoRef.current.play();
                TweenMax.to(videoRef.current, 1, { y: 0, autoAlpha: 1 });
            }}
        );

    };

    useInViewport(ref, { threshold: 1 }, { }, { onEnterViewport });
    useEffect(() => {
        TweenMax.set(buttonRef.current, { autoAlpha: 0 });
        TweenMax.set(replayRef.current, { autoAlpha: 0 });

        videoRef.current.addEventListener('ended', () => {
            TweenMax.fromTo([buttonRef.current, replayRef.current], 1,
                { y: 50, autoAlpha: 0 },
                { y: 0, autoAlpha: 1 }
            );
        }, false);
    }, []);

    return (
        <div className={ `${ styles.PincodeVideo } ${ styles[getLocalization()] }` } ref={ ref }>
            <video ref={ videoRef } src={ localize(getPersonaPincodeVideoKey(persona)) } muted />
            <div className={ styles.buttonContainer } ref={ buttonRef }>
                <Button onClick={ openModal } className={ styles.button } large black>
                    { localize('STYLEBOARD_PINCODE_CTA') }
                </Button>
            </div>

            <div className={ styles.replay } ref={ replayRef } onClick={ onReplay }>
                <div className={ styles.icon }>
                    <img src="/images/icon-replay.svg" alt="Replay" />
                </div>
                <span>{ localize('STYLEBOARD_PINCODE_REPLAY') }</span>
            </div>
        </div>
    );
};

export default PincodeVideo;
