// Modules.
import React from 'react';
import * as PIXI from 'pixi.js-legacy';
import TweenMax, { Expo, Power3, Sine } from 'gsap';
import PropTypes from 'prop-types';

// Styles.
import styles from './Slider.module.scss';

export default class Slider extends React.Component {

    static get propTypes() {
        return {
            answers: PropTypes.array.isRequired,
            cta: PropTypes.string.isRequired,
            onSelect: PropTypes.func,
            opts: PropTypes.object.isRequired,
            value: PropTypes.number,
        }
    }

    static get defaultProps() {
        return {
            onSelect: f => f,
            value: null,
        }
    }

    onAnimationInit = () => {

        TweenMax.set(this.knob, { scale: 0 });
        TweenMax.set(this.callToAction, { y: 30, opacity: 0 });
        TweenMax.set([this.ctaLeftRingOne, this.ctaLeftRingTwo, this.ctaLeftRingThree, this.ctaRightRingOne, this.ctaRightRingTwo, this.ctaRightRingThree], { opacity: 0 });
        TweenMax.set(this.ctaLeftRingOne, { x: 5 + 4 + 1 });
        TweenMax.set(this.ctaLeftRingTwo, { x: 5 + 6 + 1 });
        TweenMax.set(this.ctaLeftRingThree, { x: 5 });
        TweenMax.set(this.ctaRightRingOne, { x: -5 - 4 - 1 });
        TweenMax.set(this.ctaRightRingTwo, { x: -5 - 6 - 1 });
        TweenMax.set(this.ctaRightRingThree, { x: -5 });
        TweenMax.set(this.trackLeft, { transformOrigin: 'right center', scaleX: 0 });
        TweenMax.set(this.trackRight, { transformOrigin: 'left center', scaleX: 0 });
        TweenMax.set(this.trackBall, { scale: 0 });
        TweenMax.set(this.destLeft, { x: '300%', opacity: 0 });
        TweenMax.set(this.destRight, { x: '-300%', opacity: 0 });
        TweenMax.set(this.arrows, { opacity: 0 });
        TweenMax.set([this.answerLeft, this.answerRight], { y: 30, opacity: 0 });

        if (this.imageLeft && this.imageRight) TweenMax.set([this.imageLeft, this.imageRight], { y: -30, opacity: 0 });

    }

    onAnimateIn = (delay = null) => {

        TweenMax.to(this.knob, 2, { scale: 1, ease: Power3.easeOut, delay, onStart: this.enableTouch });
        TweenMax.to(this.callToAction, 1.5, { y: 0, opacity: 1, ease: Power3.easeOut, delay });
        TweenMax.to([this.ctaLeftRingThree, this.ctaRightRingThree], 0.5, { opacity: 1, x: 0, delay: delay + 0.5, ease: Sine.easeOut });
        TweenMax.to([this.ctaLeftRingTwo, this.ctaRightRingTwo], 0.5, { opacity: 1, x: 0, delay: delay + 0.8, ease: Sine.easeOut });
        TweenMax.to([this.ctaLeftRingOne, this.ctaRightRingOne], 0.5, { opacity: 1, x: 0, delay: delay + 1.1, ease: Sine.easeOut });
        TweenMax.to([this.trackLeft, this.trackRight], 4, { scaleX: 1, ease: Expo.easeOut, delay: delay + 0.25 });
        TweenMax.to(this.trackBall, 1, { scale: 1, delay: delay + 1 });
        TweenMax.to([this.destLeft, this.destRight], 3, { x: '0%', opacity: 1, ease: Power3.easeOut, delay: delay + 0.85 });
        TweenMax.to(this.arrows, 1, { opacity: 1, delay: delay + 1 });
        TweenMax.to(this.answerLeft, 1.5, { y: 0, opacity: 1, ease: Power3.easeOut, delay: delay + 1.75 });
        TweenMax.to(this.answerRight, 1.5, { y: 0, opacity: 1, ease: Power3.easeOut, delay: delay + 1.9 });

        if (this.imageLeft) TweenMax.to(this.imageLeft, 1.5, { y: 0, opacity: 1, ease: Power3.easeOut, delay: delay + 1.9 });
        if (this.imageRight) TweenMax.to(this.imageRight, 1.5, { y: 0, opacity: 1, ease: Power3.easeOut, delay: delay + 2.05 });

    }

    onAnimateOut = () => {

        this.disableTouch();

        const { opts } = this.props;
        const { width } = opts;
        const duration = 0.6;

        TweenMax.set(this.trackLeft, { transformOrigin: 'left center' });
        TweenMax.set(this.trackRight, { transformOrigin: 'right center' });

        // Reset the answers back to idle.
        TweenMax.to([this.answerLeft, this.answerRight], duration, { y: 0, color: '#24272a', opacity: 1 });

        if (this.imageLeft && this.imageRight) {
            TweenMax.to([this.imageLeft, this.imageRight], duration, { y: 0, scale: 1, opacity: 1 });
        }

        return new Promise(resolve => {

            TweenMax.to(this.knob, 1.5, { x: (width / 2), ease: Expo.easeOut });
            TweenMax.to(this.draggable, 1.5, { x: (width / 2), ease: Expo.easeOut, });
            TweenMax.to([this.trackLeft, this.trackRight], 2, { scaleX: 0, ease: Expo.easeOut, delay: 0.25 });
            TweenMax.to(this.answerLeft, 1.5, { x: '-100%', opacity: 0, ease: Power3.easeOut, delay: 0.75 });
            TweenMax.to(this.answerRight, 1.5, { x: '100%', opacity: 0, ease: Power3.easeOut, delay: 0.75 });
            TweenMax.to(this.destLeft, 2, { x: '-150%', opacity: 0, ease: Power3.easeOut, delay: 1.25 });
            TweenMax.to(this.destRight, 2, { x: '150%', opacity: 0, ease: Power3.easeOut, delay: 1.25, onComplete: () => resolve(true) });
            TweenMax.to(this.knob, 1.5, { scale: 0, ease: Power3.easeOut, delay: 1.5 });
            TweenMax.to(this.trackBall, 1, { scale: 0, delay: 1.5 });

            if (this.imageLeft && this.imageRight) {
                TweenMax.to(this.imageLeft, 1.5, { x: '-100%', opacity: 0, ease: Power3.easeOut, delay: 1 });
                TweenMax.to(this.imageRight, 1.5, { x: '100%', opacity: 0, ease: Power3.easeOut, delay: 1 });
            }

        });

    }

    componentDidMount() {

        this.onAnimationInit();

        const { opts } = this.props;
        const { width, height } = opts;

        // Create the PIXI app.
        const app = new PIXI.Application({
            ...opts,
            transparent: true,
            antialias: true,
            view: this.canvas,
            forceCanvas: true,
        });

        // Create the draggable container.
        this.draggable = new PIXI.Container();
        this.draggable.hitArea = new PIXI.Circle(0, (height / 2), ((height / 2) - 1));

        // Create the first clickable container.
        this.dest1 = new PIXI.Container();
        this.dest1.hitArea = new PIXI.Circle((height / 2), (height / 2), (height / 4));

        // Create the second clickable container.
        this.dest2 = new PIXI.Container();
        this.dest2.hitArea = new PIXI.Circle((width - (height / 2)), (height / 2), (height / 4));

        // Create the slider trail.
        const trail = new PIXI.Graphics();

        // Add the PIXI objects to the scene.
        app.stage.addChild(trail);
        app.stage.addChild(this.dest1);
        app.stage.addChild(this.dest2);
        app.stage.addChild(this.draggable);

        // Move our draggable to the center of the track.
        TweenMax.set([this.draggable, this.knob], { x: (width / 2) });

        // Set up our pointer events.
        this.draggable.on('pointerdown', onDragStart)
                      .on('pointerup', onDragEnd)
                      .on('pointerupoutside', onDragEnd)
                      .on('pointermove', onDragMove);

        // Set up our click listeners.
        this.dest1.on('pointerup', () => this.onClick(0));
        this.dest2.on('pointerup', () => this.onClick(1));

        const self = this;

        function onDragStart(e) {

            this.data = e.data;
            this.dragging = true;

            TweenMax.to([self.callToAction, self.arrows], 0.5, { autoAlpha: 0 });
            TweenMax.to(self.knob, 0.25, { scale: 0.9 });

        }

        function onDragEnd() {

            this.dragging = false;
            this.data = null;

            let dest = undefined;
            let value = null;

            switch(true) {
                case this.x < (width * 0.33):
                    dest = (height / 2);
                    value = 0;
                    break;
                case this.x > (width * 0.66):
                    dest = (width - (height / 2));
                    value = 1;
                    break;
                default:
                    dest = (width / 2);
                    TweenMax.to([self.callToAction, self.arrows], 1, { autoAlpha: 1 });
                    break;
            }

            TweenMax.to([this, self.knob], 1, { x: dest, ease: Expo.easeOut });
            TweenMax.to(self.knob, 0.25, { scale: 1 });

            self.moveAnswers(dest, (dest === (width / 2)) ? 1 : 0.5);
            self.onSelect(value);

        }

        function onDragMove() {
            if (this.dragging) {

                const x = Math.max((height / 2), Math.min((width - (height / 2)), this.data.getLocalPosition(this.parent).x));

                TweenMax.set(this, { x });
                TweenMax.set(self.knob, { x });

                self.moveAnswers(x, 0.25);

            }
        }

        app.ticker.add(() => {
            trail.clear();
            trail.lineStyle(6, 0xE71324);
            trail.moveTo((width / 2), (height / 2));
            trail.lineTo(this.draggable.x, (height / 2));
            trail.closePath();
        });

    }

    enableTouch = () => {

        this.draggable.interactive = true;
        this.draggable.buttonMode  = true;
        this.dest1.interactive     = true;
        this.dest1.buttonMode      = true;
        this.dest2.interactive     = true;
        this.dest2.buttonMode      = true;

        if (this.imageLeft && this.imageRight) {
            this.imageLeft.style.pointerEvents = 'auto';
            this.imageRight.style.pointerEvents = 'auto';
        }

    }

    disableTouch = () => {

        this.draggable.interactive = false;
        this.draggable.buttonMode  = false;
        this.dest1.interactive     = false;
        this.dest1.buttonMode      = false;
        this.dest2.interactive     = false;
        this.dest2.buttonMode      = false;

        if (this.imageLeft && this.imageRight) {
            this.imageLeft.style.pointerEvents = 'none';
            this.imageRight.style.pointerEvents = 'none';
        }

    }

    moveAnswers = (x, duration = 0.25, moveBoth = false) => {

        const { opts } = this.props;
        const { width, height } = opts;

        const offset = (x / width);
        const magnitude = Math.max(Math.abs(offset - 0.5), 0.25);
        const targetAnswer = (offset <= 0.5) ? this.answerLeft : this.answerRight;

        TweenMax.killTweensOf(targetAnswer);

        if (moveBoth) {
            TweenMax.to([this.answerLeft, this.answerRight], duration, { y: 0 });
        }

        TweenMax.to(targetAnswer, duration, { y: (((magnitude - 0.25) * 4) * (height / 2)) });

        // Conditionally move the images.
        if (this.imageLeft && this.imageRight) {

            const targetImage = (offset <= 0.5) ? this.imageLeft : this.imageRight;

            TweenMax.killTweensOf(targetImage);

            if (moveBoth) {
                TweenMax.to([this.imageLeft, this.imageRight], duration, { y: 0, scale: 1 });
            }

            TweenMax.to(targetImage, duration, { y: (((magnitude - 0.25) * 4) * (height / 2) * -1), scale: ((((magnitude - 0.25) * 4) * 0.25) + 1) });

        }

    }

    onClick = value => {

        const { opts } = this.props;
        const { width, height } = opts;

        const dest = (value === 0) ? (height / 2) : (width - (height / 2));

        TweenMax.to([this.callToAction, this.arrows], 0.5, { autoAlpha: 0 });
        TweenMax.to(this.draggable, 1, { x: dest, ease: Expo.easeOut });
        TweenMax.to(this.knob, 1, { x: dest, ease: Expo.easeOut });
        TweenMax.to(this.knob, 0.25, { scale: 1 });

        this.moveAnswers(dest, 0.5, true);
        this.onSelect(value);

    }

    onSelect = value => {

        const duration      = 0.3;
        const faded         = 0.25;
        const idleColor     = '#24272a';
        const selectedColor = '#e71324';

        switch (value) {

            case 0:

                TweenMax.to(this.answerLeft, duration, { opacity: 1, color: selectedColor });
                TweenMax.to(this.answerRight, duration, { opacity: faded, color: idleColor });

                if (this.imageLeft && this.imageRight) {
                    TweenMax.to(this.imageLeft, duration, { opacity: 1 });
                    TweenMax.to(this.imageRight, duration, { opacity: faded });
                }

                break;

            case 1:

                TweenMax.to(this.answerLeft, duration, { opacity: faded, color: idleColor });
                TweenMax.to(this.answerRight, duration, { opacity: 1, color: selectedColor });

                if (this.imageLeft && this.imageRight) {
                    TweenMax.to(this.imageLeft, duration, { opacity: faded });
                    TweenMax.to(this.imageRight, duration, { opacity: 1 });
                }

                break;

            default:
            case null:

                TweenMax.to([this.answerLeft, this.answerRight], duration, { color: idleColor, opacity: 1 });

                if (this.imageLeft && this.imageRight) {
                    TweenMax.to([this.imageLeft, this.imageRight], duration, { opacity: 1 });
                }

                break;

        }

        this.props.onSelect(value);

    }

    render() {

        const { answers, cta, opts } = this.props;
        const { width, height } = opts;

        return (
            <div className={ styles.Slider } style={{ width }}>
                <div className={ styles.images }>
                    {
                        answers[0].img &&
                        <div
                            className={ `${ styles.imageContainer } ${ styles.one }` }
                            style={{ width: (height * 1.5), height: (height * 1.5) }}
                            ref={ el => this.imageLeft = el }
                            onClick={ () => this.onClick(0) }
                        >
                            <div className={ styles.image } style={{ backgroundImage: `url(${ answers[0].img })` }} />
                        </div>
                    }
                    {
                        answers[1].img &&
                        <div
                            className={ `${ styles.imageContainer } ${ styles.two }` }
                            style={{ width: (height * 1.5), height: (height * 1.5) }}
                            ref={ el => this.imageRight = el }
                            onClick={ () => this.onClick(1) }
                        >
                            <div className={ styles.image } style={{ backgroundImage: `url(${ answers[1].img })` }} />
                        </div>
                    }
                </div>
                <div className={ styles.canvasContainer } style={{ width, height }}>
                    <canvas ref={ el => this.canvas = el } style={{ width, height }}></canvas>
                    <div className={ styles.knob } ref={ el => this.knob = el } style={{ width: height, height, left: -(height / 2) }}>
                        <img src="/images/drag.png" alt="Hand" />
                    </div>
                    <div className={ `${ styles.dest } ${ styles.one }` } style={{ width: (height / 2), height: (height / 2), left: (height / 4) }} ref={ el => this.destLeft = el }></div>
                    <div className={ `${ styles.dest } ${ styles.two }` } style={{ width: (height / 2), height: (height / 2), right: (height / 4) }} ref={ el => this.destRight = el }></div>
                    <div className={ styles.track } style={{ left: (height / 2), width: (width - height) }}>
                        <div className={ styles.left } ref={ el => this.trackLeft = el } />
                        <div className={ styles.right } ref={ el => this.trackRight = el } />
                        <div className={ styles.ball } style={{ width: (height / 2 * 0.2), height: (height / 2 * 0.2) }} ref={ el => this.trackBall = el } />
                    </div>
                    <div className="arrows" ref={ el => this.arrows = el }>
                        <div className={ `${ styles.arrow } ${ styles.left }` } style={{ width: (height / 10), height: (height / 10), right: `calc(50% + ${ (height / 2) }px + 5px)` }}></div>
                        <div className={ `${ styles.arrow } ${ styles.right }` } style={{ width: (height / 10), height: (height / 10), left: `calc(50% + ${ (height / 2) }px + 5px)` }}></div>
                    </div>
                    <h4 className={ styles.callToAction } ref={ el => this.callToAction = el }>
                        <span className={ styles.ring } ref={ el => this.ctaLeftRingOne = el } />
                        <span className={ styles.ring } ref={ el => this.ctaLeftRingTwo = el } />
                        <span className={ styles.ring } ref={ el => this.ctaLeftRingThree = el } />
                        { cta }
                        <span className={ styles.ring } ref={ el => this.ctaRightRingThree = el } />
                        <span className={ styles.ring } ref={ el => this.ctaRightRingTwo = el } />
                        <span className={ styles.ring } ref={ el => this.ctaRightRingOne = el } />
                    </h4>
                    <h3 className={ `${ styles.answers } ${ styles.one }` } style={{ top: `calc(100% - ${ (height / 6) }px)`, left: (height / 2) }} ref={ el => this.answerLeft = el }>{ answers[0].label }</h3>
                    <h3 className={ `${ styles.answers } ${ styles.two }` } style={{ top: `calc(100% - ${ (height / 6) }px)`, right: (height / 2) }} ref={ el => this.answerRight = el }>{ answers[1].label }</h3>
                </div>
            </div>
        );

    }

}
