// Modules.
// import { Howl } from 'howler';

class AudioManager {

    get defaults() {
        return {
            SOUNDS: {
                FIREBALL: 'fireball',
                COIN: 'coin',
                JUMP: 'jump',
                PAUSE: 'pause',
            },
        }
    }

    constructor() {
        this.state = { ...this.defaults };
        this.create();
    }

    create() {

        // this.sound = new Howl({
        //     src: ['/sounds/sprite.wav'],
        //     sprite: {
        //         fireball: [0, 131],
        //         coin: [131, 945],
        //         jump: [1077, 590],
        //         pause: [1667, 685],
        //     },
        // });

    }

    play(SOUND_ID) {
        // this.sound.play(SOUND_ID);
    }

    mute() {
        this.sound.volume(0);
    }

    unmute() {
        this.sound.volume(1);
    }

}

export default AudioManager;
