import React from 'react';

// Styles
import styles from './Loader.module.scss';

const Loader = () => {
    return (
        <div className={ styles.Loader }></div>
    )
}
export default Loader;
