import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';

// Components
import Button from 'components/Button';

// Styles
import styles from './ExitModal.module.scss';

// Utils
import GoogleAnalytics from 'utils/analytics';
import { QuizContext } from 'utils/context';
import { localize } from 'utils/localization';

const ExitModal = ({ history }) => {
    const context = useContext( QuizContext );

    const onClickRestart = () => {
        GoogleAnalytics.button.click('Exit Modal Exit Button');
        context.closeModal();
        history.push('/reset');
    };

    const onClickExit = () => {
        GoogleAnalytics.button.click('Exit Modal Close Button');
        context.closeModal();
    };

    return(
        <div className={ styles.ExitModal }>
            <h2>{ localize( 'MODAL_EXIT_HEADER' ) }</h2>
            <div className= { styles.buttonWrapper }>
                <Button large onClick={ onClickRestart }>
                    { localize( 'MODAL_EXIT_RESTART_BUTTON' ) }
                </Button>
                <Button onClick={ onClickExit } large black>
                    { localize( 'MODAL_EXIT_CLOSE_BUTTON' ) }
                </Button>
            </div>
        </div>
    );
}

export default withRouter(ExitModal);
