export const MAX_QUESTIONS = 5;

export const ModalTypes = {
    PINBOARD_LINK_FORM: 'PINBOARD_LINK_FORM',
    EXIT_MODAL: 'EXIT_MODAL',
    TIMEOUT_MODAL: 'TIMEOUT_MODAL',
    AGE_GATE: 'AGE_GATE',
};

export const Languages = {
    ENGLISH: 'en',
    SPANISH: 'es',
};

export const QuestionType = {
    FINGERS_IMAGE_TAP: 'FINGERS_IMAGE_TAP',
    IMAGE_TAP: 'IMAGE_TAP',
    SLIDER: 'SLIDER',
};
