import React, { useContext } from 'react';

// Components
import Button from 'components/Button';

// Utils
// import GoogleAnalytics from 'utils/analytics';
import { QuizContext } from 'utils/context';
import { localize } from 'utils/localization';
import { ModalTypes } from 'utils/constants';

// Styles
import styles from './AgeGateModal.module.scss';

const AgeGateModal = () => {
    const context = useContext( QuizContext );

    return(
        <div className={ styles.AgeGateModal }>
            <h2>{ localize( 'MODAL_AGE_GATE_HEADER' ) }</h2>
            <div className= { styles.buttonWrapper }>
                <Button large onClick={ () => {
                    context.setAgeGate( true ) ;
                    context.openModal( ModalTypes.PINBOARD_LINK_FORM );
                    }
                }>
                    { localize( 'MODAL_AGE_GATE_CONFIRM_BUTTON' ) }
                </Button>
                <Button onClick={ () => context.closeModal() } large black>
                    { localize( 'MODAL_AGE_GATE_CLOSE_BUTTON' ) }
                </Button>
            </div>
        </div>
    );
}
export default AgeGateModal;
