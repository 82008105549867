// Modules.
import preloader from 'preloader';

class AssetManager {

    constructor() {
        this.create();
    }

    create() {
        this.loader = preloader({
            xhrImages: false,
            loadFullVideo: true,
        });
    }

    load(assets, callback = f => f) {

        this.loader.reset();
        this.loader.on('complete', callback);

        for (let asset of assets) this.loader.add(asset);

        this.loader.load();

    }

}

export default AssetManager;
