// Modules.
import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components.
import BackButton from 'components/BackButton';
import EmailForm from 'components/EmailForm';
import SmsForm from 'components/SmsForm';

// Utils.
import GoogleAnalytics from 'utils/analytics';
import { localize } from 'utils/localization';
import { getPersonaBoardURL } from 'utils/persona';

// Styles.
import styles from './PinboardLinkForm.module.scss';

const PinboardLinkForm = ({ hideExit, keyboardInput, location, onKeyboardOpen, onKeyboardClose, onPhysicalKeyboardChange }) => {
    const [ selectedTab, setSelectedTab ] = useState( 'email' );
    const [ privacyModal, setPrivacyModal ] = useState(false);
    const [ emailTo, setEmailTo ] = useState('');
    const [ smsTo, setSmsTo ] = useState('');

    const search = location.search;
    const params = new URLSearchParams(search);
    const persona = params.get('type');
    const pinterestUrl= getPersonaBoardURL(persona);

    const onPrivacyModalTrigger = open => {
        GoogleAnalytics.button.click(`Email/SMS Modal Privacy Policy ${ open ? 'Open' : 'Close' } Button`);
        setPrivacyModal( open );
        hideExit( open );
    };

    const handleTabSelect = tab => {
        if (tab === selectedTab) return;
        onKeyboardClose();

        GoogleAnalytics.button.click(`Email/SMS Modal ${ tab } Tab`);
        setSelectedTab(tab);
    };

    useEffect(() => {
        if (keyboardInput === null) return;
        if (selectedTab === 'email') {
            setEmailTo(keyboardInput);
        } else {
            setSmsTo(keyboardInput);
        }
    }, [ keyboardInput, selectedTab ]);

    return (
        <div className={ styles.PinboardLinkForm }>
            {/* Form data Content */}
            <div className={ classnames({ [ styles.form ]: true, [ styles.hidden ]: privacyModal }) } >
                <h2 className={ styles.title } >{ localize('MODAL_PINBOARD_HEADER_TITLE') }</h2>
                <div className={ styles.tab }>
                    <div
                        className={ classnames({ [ styles.tabBtn ]: true, [ styles.active ]: selectedTab === 'email' }) }
                        onClick={ () => handleTabSelect('email') }
                    >
                        { localize('MODAL_PINBOARD_EMAIL_TAB_LABEL') }
                    </div>
                    <div
                        className= { classnames({ [ styles.tabBtn ]: true, [ styles.active ]: selectedTab === 'SMS' }) }
                        onClick={ () => handleTabSelect('SMS') }
                    >
                        { localize('MODAL_PINBOARD_SMS_TAB_LABEL') }
                    </div>
                </div>

                { (selectedTab === 'email') ?
                    <EmailForm
                        placeholder={ localize('MODAL_PINBOARD_EMAIL_PLACEHOLDER') }
                        privacy={ () => onPrivacyModalTrigger( true ) }
                        setSendTo={ value => {
                            setEmailTo(value);
                            onPhysicalKeyboardChange(value);
                        } }
                        sendTo={ emailTo }
                        pinterestUrl={ pinterestUrl }
                        onKeyboardOpen={ () => onKeyboardOpen(selectedTab, emailTo) }
                    />
                    : <SmsForm
                        placeholder={ localize('MODAL_PINBOARD_SMS_PLACEHOLDER') }
                        privacy={ () => onPrivacyModalTrigger( true ) }
                        setSendTo={ value => {
                            setSmsTo(value);
                            onPhysicalKeyboardChange(value);
                        } }
                        sendTo={ smsTo }
                        pinterestUrl={ pinterestUrl }
                        onKeyboardOpen={ () => onKeyboardOpen(selectedTab, smsTo) }
                    />
                }
            </div>

            {/*  Privacy Content */}
            <div className={ classnames({[ styles.privacyBack ]: true, [ styles.hidden ]: !privacyModal }) } onClick={ () => onPrivacyModalTrigger( false ) }>
                <BackButton />
            </div>
            <div className={ classnames({ [ styles.privacy ]: true, [ styles.hidden ]: !privacyModal }) } id={ styles.privacy }>
                <h2>{ localize('MODAL_PRIVACY_HEADER_TITLE') }</h2>
                <div className={ styles.privacyContent }>
                    <div>{ localize('MODAL_PRIVACY_CONTENT') }</div>
                </div>
            </div>
        </div>
    );
}

PinboardLinkForm.propTypes = {
    hideExit: PropTypes.func,
};

PinboardLinkForm.defaultProps = {
    hideExit: null,
};

export default withRouter(PinboardLinkForm);
