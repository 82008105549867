// Modules.
import React from 'react';

// Styles.
import styles from './BackButton.module.scss';

// Utils
import { localize } from 'utils/localization';

const BackButton = () => (
    <div className={ styles.BackButton }>
        <div className={ styles.icon } />
        <div className={ styles.label }> { localize( 'CORE_BACK_BUTTON_LABEL' ) } </div>
    </div>
);

export default BackButton;
