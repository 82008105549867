// Modules.
import React, { useCallback, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

// Components.
import Button from 'components/Button';

// Utils.
import GoogleAnalytics from 'utils/analytics';
import { QuizContext } from 'utils/context';
import { localize } from 'utils/localization';
import IdleManager from 'utils/managers/IdleManager';

// Styles.
import styles from './TimeoutModal.module.scss';

const TimeoutModal = ({ history, location }) => {
    const context = useContext( QuizContext );

    const onClickClose = () => {
        GoogleAnalytics.button.click('Timeout Modal Close Button');
        context.setTimer(true);
    };

    const onClickRestart = () => {
        GoogleAnalytics.button.click('Timeout Modal Restart Button');
        context.closeModal();
        history.push('/reset');
    };

    const onTimedOut = useCallback(() => {
        GoogleAnalytics.timeout(`${ location.pathname }${ location.search }`);
        context.closeModal();
        history.push('/reset');
    }, [ context, history, location ]);

    useEffect(() => {
        IdleManager.startIdleTimer( onTimedOut, 60000 );
    }, [ onTimedOut ]);

    return (
        <div className={ styles.TimeoutModal }>
            <h2>{ localize( 'MODAL_TIMEOUT_HEADER' ) }</h2>
            <div className={ styles.timerImage }>
                <img src="/images/timeout-clock.png" alt={ localize('MODAL_TIMEOUT_IMG_ALT')}/>
            </div>
            <div className={ styles.buttonWrapper }>
                <Button large onClick={ onClickClose }>
                    { localize('MODAL_TIMEOUT_CLOSE_BUTTON') }
                </Button>
                <Button large className={ styles.restartButton } onClick={ onClickRestart }>
                    { localize('MODAL_TIMEOUT_RESTART_BUTTON') }
                </Button>
            </div>
        </div>
    );
}

export default withRouter(TimeoutModal);
