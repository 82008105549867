// Modules.
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import TweenMax from 'gsap';

// Styles.
import styles from './ProgressBar.module.scss';

const ProgressBar = ({ height, progress, width }) => {
    const progressBarRef = useRef(null);
    const progressRef = useRef(null);

    const [ scrollDistance, setScrollDistance ] = useState(0);

    const style = {
        height: `${ height }px`,
        borderRadius: `${ height * 0.5 }px`,
    };

    useEffect(() => {
        TweenMax.to(progressRef.current, 0.1,
            { x: `${ progress *  scrollDistance }px` }
        );
    }, [ progress, scrollDistance ]);

    useEffect(() => {
        const containerPx = progressBarRef.current.offsetWidth;
        const scrollbarPx = progressRef.current.offsetWidth;
        setScrollDistance(containerPx - scrollbarPx);
    }, [ width ]);

    return (
        <div className={ styles.ProgressBar } style={ height ? style : null } ref={ progressBarRef }>
            <div className={ styles.progress } style={ {width: `${ width * 100 }%`} } ref={ progressRef } />
        </div>
    );
};

ProgressBar.propTypes = {
    height: PropTypes.number,
    progress: PropTypes.number,
    scrollWidth: PropTypes.number,
};

ProgressBar.defaultProps = {
    height: null,
    progress: 0,
    width: 0,
};

export default ProgressBar;
