// Modules.
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import TweenMax, { Power3 } from 'gsap';

// Styles.
import styles from './Pagination.module.scss';

// Utils.
import useTransitions from 'hooks/useTransitions';

const size = 34;
const margin = 12;

const Pagination = React.forwardRef((props, animRef) => {

    const node = useRef(null);
    const pages = useRef([...Array(props.total).keys()].map(() => React.createRef()));
    const dots  = useRef([...Array(props.total).keys()].map(() => React.createRef()));

    useEffect(() => {

        if (props.index > 0) {

            for (let i = 0; i < props.total; i++) {

                TweenMax.to(pages.current[i].current, 0.6, { scale: 1, backgroundColor: '#f8f5ee' });

                if (i <= props.index) TweenMax.to(dots.current[i].current, 0.6, { backgroundColor: '#e71324' });

            }

            TweenMax.to(pages.current[props.index].current, 0.6, { scale: 1.1, backgroundColor: '#fdfdfd' });

        }

    }, [props.index, props.total]);

    const onAnimateIn = (delay = null) => {

        const pivot = (props.total - 1) / 2;

        for (let i = 0; i < props.total; i++) {

            const offset = Math.ceil(i - pivot) * -1;

            TweenMax.fromTo(pages.current[i].current, 1.5, {
                scale: 0,
                x: (size * offset) + (margin * offset * 2),
            }, {
                scale: 1,
                delay,
                ease: Power3.easeOut,
                onComplete: () => {
                    TweenMax.to(pages.current[i].current, 2, { x: 0, ease: Power3.easeOut, onComplete: (i === 0) ? () => {
                        TweenMax.to(pages.current[props.index].current, 0.6, { scale: 1.1, backgroundColor: '#fdfdfd', ease: Power3.easeOut });
                        TweenMax.to(dots.current[props.index].current, 0.6, { backgroundColor: '#e71324' });
                    } : null });
                }
            });

        }

    };

    const onAnimateOut = (delay = null) => {
        return new Promise(resolve => {

            const pivot = (props.total - 1) / 2;

            for (let i = 0; i < props.total; i++) {

                const offset = Math.ceil(i - pivot) * -1;

                TweenMax.to(pages.current[i].current, 1.5, { x: (size * offset) + (margin * offset * 2), ease: Power3.easeOut, delay, onComplete: () => {
                    TweenMax.to(pages.current[i].current, 1, { scale: 0, ease: Power3.easeOut, onComplete: () => resolve(true) });
                }});

            }

        });
    };

    if (animRef) {
        useTransitions(animRef, f => f, onAnimateIn, onAnimateOut);
    }

    return (
        <div className={ styles.Pagination } ref={ node }>
            {
                [...Array(props.total).keys()].map(key => {

                    return (
                        <div className={ styles.page } key={ key } ref={ pages.current[key] } style={{ width: size, height: size, margin: `0 ${ margin }px` }}>
                            <div className={ styles.dot } ref={ dots.current[key] } />
                        </div>
                    );

                })
            }
        </div>
    );

});

Pagination.propTypes = {
    index: PropTypes.number,
    total: PropTypes.number,
};

Pagination.defaultProps = {
    index: 0,
    total: 0,
};

export default Pagination;
