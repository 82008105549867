// Modules.
import React, { useContext, useEffect, useState, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { PropTypes } from 'prop-types';

// Components.
import Button from 'components/Button';
import ExitButton from 'components/ExitButton';
import HeaderTitle from 'components/HeaderTitle';
import Question from 'components/Question';
import Pagination from 'components/Pagination';

// Utils.
import { animateHandler } from './QuestionSection.animations';
import GoogleAnalytics from 'utils/analytics';
import { QuizContext } from 'utils/context';
import { MAX_QUESTIONS, ModalTypes } from 'utils/constants';
import { localize } from 'utils/localization';
import { getQuestionConfig } from 'utils/questionnaire';
import AudioManager from 'utils/managers/AudioManager';
import AssetManager from 'utils/managers/AssetManager';
import usePrevious from 'hooks/usePrevious';

// Styles.
import styles from './QuestionSection.module.scss';

const QuestionSection = ({ history, match }) => {

    const questionRef = useRef(null);
    const paginationRef = useRef(null);
    const buttonRef = useRef(null);
    const exitRef = useRef(null);
    const headerRef = useRef(null);

    const sectionIn = match !== null;
    const questionIndex = parseInt(match.params.index);

    const context = useContext(QuizContext);
    const [ config, setConfig ] = useState(null);
    const [ question, setQuestion ] = useState(null);
    const [ selection, setSelection ] = useState(null);
    const [ isAnimating, setIsAnimating ] = useState(null);

    const prevQuestion = usePrevious(question);

    // When the question number is updated, retrieve the configuration for the...
    // ...question and make any limitation updates as necessary.
    useEffect(() => {

        GoogleAnalytics.pageview(`/question/${ questionIndex }`);

        const questionConfig = getQuestionConfig(questionIndex);
        const optionIndex = Math.floor(questionConfig.questions.length * Math.random());

        setConfig(questionConfig);
        setQuestion(questionConfig.questions[ optionIndex ]);
        setSelection(null);

        GoogleAnalytics.question.loaded(questionIndex, optionIndex);

    }, [ questionIndex ]);

    const onAnswerConfirmed = () => {

        GoogleAnalytics.button.click(`Question ${ questionIndex } Next Button`);
        GoogleAnalytics.question.completed(questionIndex);

        setIsAnimating(true);

        if (config.next && questionIndex < MAX_QUESTIONS) {

            AudioManager.play(AudioManager.state.SOUNDS.FIREBALL);

            const pushNextQuestion = () => {

                context.update(question.answers[selection].points);
                history.push(`/question/${ questionIndex + 1 }`);

                setIsAnimating(false);
                headerRef.current && headerRef.current.onShowText();

            };

            headerRef.current && headerRef.current.onHideText((questionIndex % 2 === 0) ? 1.45 : 0.7);
            questionRef.current ? questionRef.current.onAnimateOut().then(pushNextQuestion) : pushNextQuestion();

        } else {

            AudioManager.play(AudioManager.state.SOUNDS.COIN);

            const pushPersona = () => {

                setIsAnimating(false);

                const persona = (question.answers[selection].points.length > 0) ? question.answers[selection].points[0] : null;

                history.push({
                    pathname: '/persona',
                    search: `?type=${ persona }`
                });

            };

            Promise.all([
                questionRef.current.onAnimateOut(),
                paginationRef.current.onAnimateOut(),
                buttonRef.current.onAnimateOut(1),
                exitRef.current.onAnimateOut(0.5),
                headerRef.current.onAnimateOut(1),
            ]).then(pushPersona);

        }
    };

    const onSelectionChange = index => {
        if (index !== null) {
            GoogleAnalytics.question.answer.select( localize(question.answers[index].key) );
        }
        setSelection(index);
    };

    const exitScreen = () => {
        GoogleAnalytics.button.click(`Question ${ questionIndex } Exit Button`);
        context.openModal(ModalTypes.EXIT_MODAL);
    };

    useEffect(() => {
        if ((questionIndex === MAX_QUESTIONS - 1) && prevQuestion && (prevQuestion.key !== question.key)) {
            AssetManager.load(question.answers.map(answer => answer.video));
        }
    }, [question, prevQuestion, questionIndex]);

    if (!context.scoreboard) history.push('/');
    if (!question) return null;

    return (
        <Transition
            appear
            unmountOnExit
            in={ sectionIn }
            addEndListener={ (node, done) => {

                const refs = { paginationRef, buttonRef, exitRef, headerRef };
                animateHandler(node, done, sectionIn, refs);

            }}
        >
            <section className={ styles.QuestionSection }>

                <div className={ styles.headerWrapper }>
                    <HeaderTitle alignment="center" ref={ headerRef }>{ question.theme && localize(question.theme) }</HeaderTitle>
                </div>

                <div className={ styles.exit }>
                    <ExitButton onClick={ exitScreen } ref={ exitRef } />
                </div>

                <Question
                    config={ question }
                    onChange={ onSelectionChange }
                    value={ selection }
                    ref={ questionRef }
                    index={ questionIndex }
                />

                <div className={ styles.button }>
                    <Button
                        disabled={ selection === null || isAnimating }
                        onClick={ onAnswerConfirmed }
                        ref={ buttonRef }
                    >
                        { question.button ? localize( question.button ) : localize('CORE_NEXT') }
                    </Button>
                </div>

                <div className={ styles.paginationWrapper }>
                    <Pagination
                        index={ questionIndex }
                        total={ MAX_QUESTIONS }
                        ref={ paginationRef }
                    />
                </div>

            </section>
        </Transition>
    )
};

QuestionSection.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

QuestionSection.defaultProps = {
    history: null,
    match: null,
};

export default QuestionSection;
