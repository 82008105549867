// import { TweenMax } from "gsap";

// import { sectionAnimateIn, sectionAnimateOut, chainAnimations } from 'utils/animations';

/**
* Animation handler for the Home section
*
* @param {Object} node The section's DOM object.
* @param {Function} done The callback function after animations completed.
* @param {boolean} show Flag indicating whether the section is showing or hiding.
* @param {Object} refs A key-DOM dictionary to pass the references of child components.
*/
export const animateHandler = (node, done, show, refs) => {

    if (show) {

        Promise.all([
            refs.paginationRef.current.onAnimateIn(3),
            refs.buttonRef.current.onAnimateIn(2.5),
            refs.exitRef.current.onAnimateIn(1),
            refs.headerRef.current.onAnimateIn(1.5),
        ]).then(done);

    } else {

        Promise.all([
            // refs.paginationRef.current.onAnimateOut(),
            // refs.buttonRef.current.onAnimateOut(),
            // refs.exitRef.current.onAnimateOut(),
            // refs.headerRef.current.onAnimateOut(),
        ]).then(done);

    }

};
