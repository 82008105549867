// Modules.
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import scroll from 'scroll';
import ScrollBooster from 'scrollbooster';
import ease from 'ease-component';

// Components
import ExitButton from 'components/ExitButton';
import PersonaTitle from 'components/PersonaTitle';
import Pinboard from 'components/Pinboard';
import PincodeVideo from 'components/PincodeVideo';
import ProgressBar from 'components/ProgressBar';

// Utils
import { onAnimateIn } from './StyleBoardSection.animations';
import GoogleAnalytics from 'utils/analytics';
import { ModalTypes } from 'utils/constants';
import { QuizContext } from 'utils/context';
import { localize } from 'utils/localization';
import { getPersonaBoard } from 'utils/persona';

// Styles
import styles from './StyleBoardSection.module.scss';

const StyleBoardSection = ({ history, location, match }) => {
    const sectionIn = match !== null;

    const search = location.search;
    const params = new URLSearchParams(search);
    const persona = params.get('type');

    if (!persona) {
        console.error('[StyleBoardSection] No persona type found in query params.');
        history.push('/');
    }

    const context = useContext(QuizContext);
    const board = getPersonaBoard(persona);

    const headerRef = useRef(null);
    const scrollerRef = useRef(null);
    const contentWrapperRef = useRef(null);
    const pinboardRef = useRef(null);
    const progressBarRef = useRef(null);
    const scrollerPRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            // Calculate the width of the scrollbar based on content
            const contentWidth = contentWrapperRef.current.scrollWidth;
            const windowWidth = window.innerWidth;

            setScrollbarWidth(windowWidth / contentWidth);

            // Setup ScrollBooster for drag scrolling
            new ScrollBooster({
                viewport: scrollerRef.current,
                content: contentWrapperRef.current,
                mode: 'x',
                onUpdate: data => {
                    if (scrollerRef.current) {
                        scrollerRef.current.scrollLeft = data.position.x;
                    }
                },
            });
        }, 0);
    }, []);

    const [ scrollProgress, setScrollProgress ] = useState(0);
    const [ scrollbarWidth, setScrollbarWidth ] = useState(0);

    const exitScreen = () => {
        GoogleAnalytics.button.click(`Style Board Exit Button`);
        context.openModal(ModalTypes.EXIT_MODAL);
    };

    const handleScroll = e => {
        const offsetWidth = e.nativeEvent.target.offsetWidth;
        const scrollLeft = e.nativeEvent.target.scrollLeft;
        const scrollWidth = e.nativeEvent.target.scrollWidth;
        const position = scrollLeft / (scrollWidth - offsetWidth);

        setScrollProgress(position);
    };

    const scrollToEnd = () => {
        GoogleAnalytics.button.click('Style Board Scroll-to-Pincode Button');
        scroll.left(scrollerRef.current, scrollerRef.current.scrollWidth, { duration: 2500, ease: ease.inOutCube });
    };

    useEffect(() => {
        GoogleAnalytics.pageview(`${ location.pathname }${ location.search }`);
    }, [ location ]);

    return (
        <Transition
            appear
            unmountOnExit
            in={ sectionIn }
            addEndListener={ (node, done) => {
                const refs = {
                    node,
                    header: headerRef.current,
                    pinboard: pinboardRef.current,
                    progressBar: progressBarRef.current,
                    scrollerP: scrollerPRef.current,
                };
                if (sectionIn) onAnimateIn(refs, done);
            } }
        >
            <section className={ styles.StyleBoardSection }>
                <div className={ styles.header } ref={ headerRef }>
                    <PersonaTitle persona={ persona } />
                    <div className={ styles.exit }>
                        <ExitButton onClick={ exitScreen } />
                    </div>
                </div>

                <div className={ styles.container }>
                    <div className={ styles.scroller } ref={ scrollerRef } onScroll={ handleScroll }>
                        <div className={ styles.contentWrapper } ref={ contentWrapperRef }>

                            <div className={ styles.pinboard }>
                                <Pinboard ref={ pinboardRef } images={ board } onButtonClick={ scrollToEnd } />
                            </div>

                            <div className={ styles.pincode }>
                                <div className={ styles.headers }>
                                    <h2>{ localize('STYLEBOARD_PAGE_HEADER') }</h2>
                                </div>
                                <div className={ styles.video }>
                                    <PincodeVideo persona={ persona } />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className={ styles.scrollbar }>
                    <div className={ styles.progressbar } ref={ progressBarRef }>
                        <ProgressBar height={ 9 } progress={ scrollProgress } width={ scrollbarWidth } />
                    </div>

                    <div className={ styles.clicker } ref={ scrollerPRef } onClick={ scrollToEnd }>
                        <img src='/images/scroller-p.png' alt='Pinterest P' />
                    </div>
                </div>
            </section>
        </Transition>
    );
};

StyleBoardSection.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
};

StyleBoardSection.defaultProps = {
    history: null,
    location: null,
    match: null,
};

export default StyleBoardSection;
