// Modules.
import React, { useRef } from 'react';
import classnames from 'classnames';
import TweenMax, { Power3 } from 'gsap';

// Utils.
import GoogleAnalytics from 'utils/analytics';
import { Languages } from 'utils/constants';
import { getLocalization, setLocalization } from 'utils/localization';
import useTransitions from 'hooks/useTransitions';


// Styles.
import styles from './LanguageSelector.module.scss';


const LanguageSelector = React.forwardRef((_, animRef) => {

    const node = useRef(null);
    const current = getLocalization();

    const updateLocalization = localization => {
        GoogleAnalytics.button.click(`Language Select Button: ${ localization }`);
        setTimeout(() => setLocalization(localization), 200);
    };

    const onAnimateIn = (delay = null) => {
        return new Promise(resolve => {
            TweenMax.fromTo(node.current, 1.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
        });
    };

    const onAnimateOut = (delay = null) => {
        // ...
    };

    if (animRef) {
        useTransitions(animRef, f => f, onAnimateIn, onAnimateOut);
    }

    return (
        <div className={ styles.LanguageSelector } ref={ node }>
            <ul>
            { Object.keys(Languages).map((key, index) => (
                <li
                    key={ index }
                    className={ classnames({ [styles.active]: Languages[key] === current }) }
                    onClick={ () => updateLocalization(Languages[key]) }
                >
                    { Languages[key] }
                </li>
            )) }
            </ul>
        </div>
    );
});

export default LanguageSelector;
