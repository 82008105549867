// Modules.
import React, { useRef, useEffect, /* useState */ } from 'react';
import TweenMax, { Power3 } from 'gsap';
// import classnames from 'classnames';

// Utils.
import { localize } from 'utils/localization';
// import AudioManager from 'utils/managers/AudioManager';

// Styles.
import styles from './FooterBar.module.scss';

// const SoundToggle = ({ muted, onClick }) => {

//     const classes = classnames({
//         [styles.SoundToggle]: true,
//         [styles.muted]: muted,
//     });

//     return (
//         <div className={ classes } onClick={ onClick }>
//             <div />
//             <div />
//             <div />
//             <div />
//             <div />
//         </div>
//     );

// };

const FooterBar = () => {

    const node = useRef(null);
    // const [muted, setMuted] = useState(false);

    // const onClick = () => {
    //     muted ? AudioManager.unmute() : AudioManager.mute();
    //     setMuted(!muted);
    // };

    useEffect(() => {
        TweenMax.fromTo(node.current, 1.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeOut, delay: 0.75 });
    }, []);

    return (
        <div className={ styles.FooterBar } ref={ node }>
            <div className={ styles.left }>
                <img className={ styles.tagline } src={ localize('INSPIRED') } alt={ localize('INSPIRED_ALT') } />
            </div>
            {/* <div className={ styles.right }>
                <SoundToggle onClick={ onClick } muted={ muted } />
            </div> */}
        </div>
    );

};

export default FooterBar;
