// Modules.
import React from 'react';

// Components.
import Slider from '../../components/Slider';

// Styles.
import './Test.scss';

export default class Test extends React.Component {

    onSelect = value => {
        console.log(value);
    }

    render() {
        return (
            <section className="Test">
                <Slider
                    opts={{
                        width: 1350,
                        height: 120,
                        resolution: 2,
                    }}
                    cta="Drag the Circle"
                    answers={[
                        { label: 'In the city', image: 'https://i.ytimg.com/vi/1roy4o4tqQM/maxresdefault.jpg', value: 0 },
                        { label: 'Close to nature', image: 'https://secure.img1-fg.wfcdn.com/im/62945217/resize-h700-p1-w700%5Ecompr-r85/8470/84707680/Pokemon+Pikachu+Wall+Decal.jpg', value: 1 }
                    ]}
                    onSelect={ this.onSelect }
                />
            </section>
        );
    }

}
