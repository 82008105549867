// Modules.
import React, { useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import TweenMax, { Power3 } from 'gsap';
import classnames from 'classnames';

// Styles.
import styles from './HeaderTitle.module.scss';

const HeaderTitle = React.forwardRef(({ children, alignment }, animRef) => {

    const titleRef = useRef(null);
    const dividerRef = useRef(null);

    const onAnimateIn = (delay = null) => {
        return new Promise(resolve => {
            TweenMax.fromTo(titleRef.current, 1.5, { opacity: 0, y: 10 }, { opacity: 1, y: 0, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
            TweenMax.fromTo(dividerRef.current, 1.5, { transformOrigin: `${ alignment } center`, scaleX: 0 }, { scaleX: 1, ease: Power3.easeInOut, delay });
        });
    };

    const onAnimateOut = (delay = null) => {
        return new Promise(resolve => {
            TweenMax.to(titleRef.current, 1.5, { opacity: 0, y: -10, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
            TweenMax.to(dividerRef.current, 1.5, { transformOrigin: `${ alignment } center`, scaleX: 0, ease: Power3.easeInOut, delay });
        });
    };

    const onHideText = (delay = null) => {
        TweenMax.to(titleRef.current, 1, { opacity: 0, y: -10, ease: Power3.easeIn, delay });
    };

    const onShowText = (delay = null) => {
        TweenMax.fromTo(titleRef.current, 1, { opacity: 0, y: 10 }, { opacity: 1, y: 0, ease: Power3.easeOut, delay });
    };

    useImperativeHandle(animRef,
        () => ({ onHideText, onShowText, onAnimateIn, onAnimateOut }),
    );

    const classes = classnames({
        [styles.HeaderTitle]: true,
        [styles[alignment]]: true,
    });

    return (
        <div className={ classes }>
            <h5 ref={ titleRef }>{ children }</h5>
            <span className={ styles.divider } ref={ dividerRef } />
        </div>
    );

});

HeaderTitle.propTypes = {
    alignment: PropTypes.oneOf(['left', 'center'])
};

HeaderTitle.defaultProps = {
    alignment: 'left',
};

export default HeaderTitle;
