// Modules.
import React from 'react';
import PropTypes from 'prop-types';

// Component
import LinkForm from '../LinkForm';

const EmailForm = (props) => {
    const validateBeforeSubmit = ( validate ) => {
        if ( /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(validate) ) {
            return {
                statusCode: 200,
            }
        }
        else {
            return {
                statusCode: 401,
                errorMessage: `${ validate } is not a vaild E-mail address.`
            }
        }
    }

    return (
        <>
            <LinkForm
                communicationType='email'
                sendTo={ props.sendTo }
                validate={ validateBeforeSubmit }
                privacyModalActive={ props.privacy }
                pinterestUrl={ props.pinterestUrl }
                hideSms
                reset={ reset => { if (reset) props.setSendTo( '' ); } }
            >
                <input
                    type='text'
                    id={ props.communicationType }
                    name={ props.communicationType }
                    placeholder={ props.placeholder ? props.placeholder : 'user@email.com'}
                    required
                    value={ props.sendTo }
                    autoComplete='off'
                    onChange={ event => {
                        props.setSendTo( event.target.value );
                    } }
                    onFocus={ props.onKeyboardOpen }
                />
            </LinkForm>
        </>
    );
};

EmailForm.propTypes = {
    communicationType: PropTypes.string.isRequired,
    pinterestUrl: PropTypes.string,
    placeholder: PropTypes.string,
    privacy: PropTypes.func,
    setSendTo: PropTypes.func,
    sendTo: PropTypes.string,
};

EmailForm.defaultProps = {
    communicationType: 'email',
    pinterestUrl: 'http://www.pinterest.com',
    placeholder: 'email@email.com',
    privacy: null,
    setSendTo: null,
    sendTo: '',
};

export default EmailForm;
