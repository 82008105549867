// Modules.
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TweenMax, { Power3 } from 'gsap';

// Styles.
import styles from './Button.module.scss';

// Utils.
import useTransitions from 'hooks/useTransitions';

const Button = React.forwardRef(
    ({ black, className, children, disabled, hideArrow, large, onClick }, animRef) => {

        const node = useRef(null);

        const onAnimateIn = (delay = null) => {
            return new Promise(resolve => {
                TweenMax.fromTo(node.current, 1.5, { autoAlpha: 0 }, { autoAlpha: 1, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
            });
        };

        const onAnimateOut = (delay = null) => {
            return new Promise(resolve => {
                TweenMax.to(node.current, 1.5, { autoAlpha: 0, ease: Power3.easeOut, delay, onComplete: () => resolve(true) });
            });
        };

        if (animRef) {
            useTransitions(animRef, f => f, onAnimateIn, onAnimateOut);
        }

        return (
            <button
                className={ classnames({
                    [styles.Button]: true,
                    [styles.lg]: large,
                    [styles.black]: black,
                    [className]: className,
                }) }
                disabled={ disabled }
                onClick={ onClick }
                ref={ node }
            >
                <span className={ styles.label }>{ children }</span>
                { !hideArrow && <span className={ styles.caret } /> }
            </button>
        );

    }
);

Button.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([ PropTypes.string, PropTypes.object ]).isRequired,
    disabled: PropTypes.bool,
    hideArrow: PropTypes.bool,
    large: PropTypes.bool,
    onClick: PropTypes.func,
    black: PropTypes.bool,
};

Button.defaultProps = {
    className: null,
    disabled: false,
    hideArrow: false,
    large: false,
    onClick: null,
    black: false,
};

export default Button;
