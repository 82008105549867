import React from 'react';
import Privacy from './es.privacy.localization';

export default {
    // Personas.
    "PERSONA_MODERN": "Moderno",
    "PERSONA_TRADITIONAL": "Tradicional",
    "PERSONA_ECLECTIC": "Ecléctico",
    "PERSONA_GLAM": "Lujosos",
    "PERSONA_COASTAL": "Costeño",
    "PERSONA_FARMHOUSE": "Rural",

    // Persona Descriptions.
    "PERSONA_MODERN_DESCRIPTION": "La casa de tus sueños tiene una planta abierta con líneas sencillas.  Los detalles cromados y sofisticados fueron elegidos para acentuar una estética minimalista.",
    "PERSONA_TRADITIONAL_DESCRIPTION": "La casa de tus sueños es de diseño sencillo, elegante y de estilo clásico. Tu prefieres estilos atemporales con detalles más ornamentales.",
    "PERSONA_ECLECTIC_DESCRIPTION": "La casa de tus sueños está llena de vida, con colores y estilo internacional. Es una mezcla divertida y ecléctica de telas, patrones y texturas expresivas con toques de verdor.",
    "PERSONA_GLAM_DESCRIPTION": "La casa de tus sueños brilla y destella como el corazón del centro de la ciudad. Los muebles sencillos y monocromáticos se notan aún más con la adición de accesorios glamorosos.",
    "PERSONA_COASTAL_DESCRIPTION": "La casa de tus sueños está llena de luz natural y transmite un sentimiento veraniego y fresco durante todo el año. Tiene texturas naturales combinadas con decoración en azul y blanco.",
    "PERSONA_FARMHOUSE_DESCRIPTION": "La casa de tus sueños salió directamente de la revista Country Living: tiene un estilo chic rural lleno de muebles rústicos, piezas con madera natural y abundantes flores.",

    // Persona Pincode Video Sources.
    "PERSONA_MODERN_PINCODE_VIDEO_SRC": "/videos/pincode-modern-sp.webm",
    "PERSONA_TRADITIONAL_PINCODE_VIDEO_SRC": "/videos/pincode-traditional-sp.webm",
    "PERSONA_ECLECTIC_PINCODE_VIDEO_SRC": "/videos/pincode-eclectic-sp.webm",
    "PERSONA_GLAM_PINCODE_VIDEO_SRC": "/videos/pincode-glam-sp.webm",
    "PERSONA_COASTAL_PINCODE_VIDEO_SRC": "/videos/pincode-coastal-sp.webm",
    "PERSONA_FARMHOUSE_PINCODE_VIDEO_SRC": "/videos/pincode-farmhouse-sp.webm",

    // Core.
    "CORE_DRAG_THE_CIRCLE": "ARRASTRA EL CÍRCULO",
    "CORE_NEXT": "SIGUIENTE",
    "CORE_STYLE": "Estilo",
    "CORE_EXIT_BUTTON_LABEL": "Salir",
    "CORE_BACK_BUTTON_LABEL": "Regresar",
    "INSPIRED": "/images/inspired-by-pinterest-trends-sp-2.svg",
    "INSPIRED_ALT": "Inspirado en las tendencias de Pinterest",

    // Intro Screen.
    "HOME_QUIZ_TITLE": "CUESTIONARIO DE ESTILO PERSONAL",
    "HOME_INTRO_TITLE": <>Descubre tu<br />estilo personal.</>,
    "HOME_BUTTON_LABEL": "TOCA PARA INICIAR",

    // Question 1 - Option 1.
    "Q1O1_THEME": "¿CÓMO TE SIENTES?",
    "Q1O1_QUESTION": <>¿Dónde te sientes<br />más cómodo?</>,
    "Q1O1_ANSWER_1": "En la ciudad",
    "Q1O1_ANSWER_2": "Al aire libre",

    // Question 1 - Option 2.
    "Q1O2_THEME": "¿CÓMO TE SIENTES?",
    "Q1O2_QUESTION": <>¿Cómo te describirías?</>,
    "Q1O2_ANSWER_1": "Pulcro",
    "Q1O2_ANSWER_2": "Desorganizado",

    // Question 2 - Option 1.
    "Q2O1_THEME": "DESCUBRE TU INSPIRACIÓN",
    "Q2O1_QUESTION": <>Si te regalan $1000 para comprar una <br />nueva obra de arte, ¿qué escogerías?</>,
    "Q2O1_MODERN": "Pintura moderna",
    "Q2O1_TRADITIONAL": "Pintura al óleo",
    "Q2O1_ECLECTIC": "Dibujo a tinta",
    "Q2O1_GLAM": "Foto en blanco y negro",
    "Q2O1_COASTAL": "Foto escénica",
    "Q2O1_FARMHOUSE": "Pintura de paisaje",

    // Question 2 - Option 2.
    "Q2O2_THEME": "DESCUBRE TU INSPIRACIÓN",
    "Q2O2_QUESTION": <>¿Qué cortinas combinan <br />con tu estilo?</>,
    "Q2O2_MODERN": "Opaca",
    "Q2O2_TRADITIONAL": "Drapeada",
    "Q2O2_ECLECTIC": "Estampadas",
    "Q2O2_GLAM": "Brillante",
    "Q2O2_COASTAL": "De lino",
    "Q2O2_FARMHOUSE": "Transparentes",

    // Question 3 - Option 1.
    "Q3O1_THEME": "DEFINE TU PERSONALIDAD",
    "Q3O1_QUESTION": <>¿Cuál es tu género de <br />pelicula favorito?</>,
    "Q3O1_ANSWER_1": "Comedia",
    "Q3O1_ANSWER_2": "Drama",

    // Question 3 - Option 2.
    "Q3O2_THEME": "DEFINE TU PERSONALIDAD",
    "Q3O2_QUESTION": <>¿Dónde vas a estar <br />este fin de semana?</>,
    "Q3O2_ANSWER_1": "Al aire libre",
    "Q3O2_ANSWER_2": "En interiores",

    // Question 4 - Option 1.
    "Q4O1_THEME": "DESCUBRE TU ESTILO",
    "Q4O1_QUESTION": <>Si pudieras vivir en cualquier lugar, <br />¿Cuál sería?</>,
    "Q4O1_MODERN": "Contemporáneo",
    "Q4O1_TRADITIONAL": "Clásico",
    "Q4O1_ECLECTIC": "Mediterráneo",
    "Q4O1_GLAM": "A la moda",
    "Q4O1_COASTAL": "Playa",
    "Q4O1_FARMHOUSE": "Rústico",

    // Question 4 - Option 2.
    "Q4O2_THEME": "DESCUBRE TU ESTILO",
    "Q4O2_QUESTION": <>¿A dónde te encantaría viajar?</>,
    "Q4O2_MODERN": "España",
    "Q4O2_TRADITIONAL": "Londres",
    "Q4O2_ECLECTIC": "Japón",
    "Q4O2_GLAM": "Nueva York",
    "Q4O2_COASTAL": "Hawái",
    "Q4O2_FARMHOUSE": "Nashville",

    // Question 5 - Option 1.
    "Q5O1_THEME": "REFINA TU ESTILO",
    "Q5O1_QUESTION": <>Selecciona un artículo <br />para tu sala.</>,
    "Q5O1_MODERN": "Espejo",
    "Q5O1_TRADITIONAL": "Librero",
    "Q5O1_ECLECTIC": "Cojín",
    "Q5O1_GLAM": "Gabinete",
    "Q5O1_COASTAL": "Cortinas de lino",
    "Q5O1_FARMHOUSE": "Tapete floral",

    // Question 5 - Option 2.
    "Q5O2_THEME": "REFINA TU ESTILO",
    "Q5O2_QUESTION": <>¿Qué estilo de lámpara te <br />gustaría tener en tu casa?</>,
    "Q5O2_MODERN": "Sencilla",
    "Q5O2_TRADITIONAL": "De latón",
    "Q5O2_ECLECTIC": "Con patrón",
    "Q5O2_GLAM": "Glamorosa",
    "Q5O2_COASTAL": "De vidrio",
    "Q5O2_FARMHOUSE": "Rústica",
    "Q5_BUTTON": "REVELAR ESTILO",

    // Results.
    "PERSONA_PAGE_DISCOVER": "Descubre lo",
    "PERSONA_PAGE_PINBOARD_LOADING": "CARGANDO TU TABLERO DEL PINTEREST",

    // Pinterest Style Board.
    "STYLEBOARD_PAGE_TAKE_THE_BOARD": "¿Te gusta lo que ves? ¡Llévate todo lo que ves en Pinterest!",
    "STYLEBOARD_PAGE_SCAN_PINCODE": <>LLÉVATE TODO LO<br />QUE VES EN<img src="/images/pinterest-icon.svg" alt="Pinterest" /></>,
    "STYLEBOARD_PAGE_HEADER": "Escanea el código y descubre más",
    "STYLEBOARD_PINCODE_CTA": "ENVIAR VÍNCULO",
    "STYLEBOARD_PINCODE_REPLAY": "Repite",

    // SMS/Email
    "MODAL_AGE_GATE_HEADER": "¿Declaras que tienes 18 años o más?",
    "MODAL_AGE_GATE_CONFIRM_BUTTON": "SI",
    "MODAL_AGE_GATE_CLOSE_BUTTON": "NO",

    "MODAL_PINBOARD_HEADER_TITLE": "Ingresa tu correo electrónico o número telefónico",

    "MODAL_PINBOARD_EMAIL_TAB_LABEL": "CORREO ELECTRÓNICO",
    "MODAL_PINBOARD_SMS_TAB_LABEL": "TEXTO",

    "MODAL_PINBOARD_PRIVACY_LABEL": "Acepto la ",
    "MODAL_PINBOARD_PRIVACY_LINK_TEXT": "Política de privacidad",

    "MODAL_PINBOARD_EMAIL_TERMS_LABEL": "Entiendo que al ingresar mi número de teléfono móvil, acepto recibir hasta dos mensajes de texto sobre los resultados de \"JCPenney y Cuestionario de estilo personal para el hogar de Pinterest\". (Aplican tarifas de mensajes.)",
    "MODAL_PINBOARD_SMS_TERMS_LABEL": "Entiendo que al ingresar mi número de teléfono móvil, acepto recibir hasta dos mensajes de texto sobre los resultados de \"JCPenney y Cuestionario de estilo personal para el hogar de Pinterest\". (Aplican tarifas de mensajes.)",

    "MODAL_PINBOARD_EMAIL_PLACEHOLDER": "myemail@email.com",
    "MODAL_PINBOARD_SMS_PLACEHOLDER": "+X (XXX) XXX-XXXX",
    "MODAL_PINBOARD_EMAIL_TEMPLATE_ID": "d-5d1dc4fcf51f440c97aa396119bd27de",

    "MODAL_PINBOARD_PINTEREST_BUTTON_COPY": "ENVIAR VÍNCULO",

    "MODAL_ERROR_EMAIL_NOT_SET_INVALID": "Correo electrónico inválido.",
    "MODAL_ERROR_EMAIL_INVALID_FORMAT": "Formato incorrecto.",
    "MODAL_ERROR_SMS_NOT_SET_INVALID": "Número telefónico inválido.",
    "MODAL_ERROR_SMS_UNREACHABLE": "El mensaje no se puede enviar.",

    "MODAL_SUCCESS_EMAIL": "Correo electrónico enviado a",
    "MODAL_SUCCESS_SMS": "Mensaje de texto enviado a",

    "MODAL_SMS_BODY": "Gracias por llenar el cuestionario de estilo personal para el hogar de Penney's y Pinterest. Haz clic el vínculo para ver tu Tablero de Pinterest con con los estilos nuevos para tu casa. ¡Felices compras!",

    "MODAL_PRIVACY_HEADER_TITLE": "Política de privacidad",
    "MODAL_PRIVACY_CONTENT": Privacy.privacy,

    "MODAL_EXIT_HEADER": "¿Está seguro de que desea salir?",
    "MODAL_EXIT_RESTART_BUTTON": "SÍ, ESTOY SEGURO",
    "MODAL_EXIT_CLOSE_BUTTON": "NO, QUIERO QUEDARME",

    "MODAL_TIMEOUT_HEADER": "¿Aún estás aquí?",
    "MODAL_TIMEOUT_IMG_ALT": "Timeout Clock",
    "MODAL_TIMEOUT_CLOSE_BUTTON": "SÍ, AQUÍ SIGO",
    "MODAL_TIMEOUT_RESTART_BUTTON": "REINICIAR"
};
