// Modules.
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

// Styles.
import styles from './Checkbox.module.scss';

const Checkbox = React.memo(
  ({ checked, onClick, className, id }) => {
    const classes = classnames({
      [styles.Checkbox]: true,
      [styles.checked]: checked,
      [ className ]: className,
    });

    return (
      <div className={ classes } onClick={ onClick }>
        <input type="checkbox" id={ id }/>
        <svg viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </svg>
      </div>
    );
  }
);

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Checkbox;
