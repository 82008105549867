// Utils.
import { Languages } from 'utils/constants';

// Localizations.
import en from 'localization/en.localization';
import es from 'localization/es.localization';

const DEFAULT_LOCALIZATION = Languages.ENGLISH;
const STORAGE_KEY = 'jcp-localization';

/**
* Retrieves the current localization key.
*
* @return {String} Key of the current localization.
*/
export const getLocalization = () => {
    return sessionStorage.getItem(STORAGE_KEY) || DEFAULT_LOCALIZATION;
};

/**
* Resets the currently set localization.
*/
export const resetLocalization = () => {
    sessionStorage.removeItem(STORAGE_KEY);
};

/**
* Sets the localization and refreshes the window to update the app.
*
* @param {String} localization Key from Languages object of the new localization.
*/
export const setLocalization = localization => {
    sessionStorage.setItem(STORAGE_KEY, localization);
    window.location.reload();
};

/**
* Function to retrieve a localized string/component based on the key.
*
* @param {String} key Key of the string to fetch.
* @return {String} Localized string value associated to the key.
*/
export const localize = (key) => {
    switch (getLocalization()) {
        case Languages.ENGLISH: {
            if (!en[key]) {
                console.error(`[localize] Missing English String: ${ key }`);
                return;
            }
            return en[key];
        }
        case Languages.SPANISH: {
            if (!es[key]) {
                console.error(`[localize] Missing Spanish String: ${ key }`);
                return;
            }
            return es[key];
        }
        default: {
            console.error(`[localize] Missing Localization: ${ getLocalization() }`);
        }
    }
};
