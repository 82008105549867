// Modules.
import { useImperativeHandle } from 'react';

/**
* React hook to bind component animation functions to the parent ref.
*
* @param {Object} ref The component's ref from the parent component.
* @param {Function} onAnimationInit The function to set component to initial animation position.
* @param {Function} onAnimateIn The function to animate the component in.
* @param {Function} onAnimateOut The function to animate the component out.
*/
const useTransitions = (ref, onAnimationInit, onAnimateIn, onAnimateOut) => {
    if (!ref || typeof ref !== 'object')
        console.error(`[useTransitions] 'ref' parameter is required and must be of type 'object'.`);

    if (!onAnimationInit || typeof onAnimationInit !== 'function')
        console.error(`[useTransitions] 'onAnimationInit' parameter is required and must be of type 'function'.`);

    if (!onAnimateIn || typeof onAnimateIn !== 'function')
        console.error(`[useTransitions] 'onAnimateIn' parameter is required and must be of type 'function'.`);

    if (!onAnimateOut || typeof onAnimateOut !== 'function')
        console.error(`[useTransitions] 'onAnimateOut' parameter is required and must be of type 'function'.`);

    // Bind the object to the ref passed down from the parent, allowing the...
    // ...parent to then call the function using the ref (Ex. childRef.onAnimateIn())
    useImperativeHandle(ref,
        () => ({
            onAnimationInit: onAnimationInit || (() => {}),
            onAnimateIn: onAnimateIn || (() => {}),
            onAnimateOut: onAnimateOut || (() => {}),
        })
    );
};

export default useTransitions;
