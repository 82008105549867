import React from 'react';
import Privacy from './en.privacy.localization';

export default {
    // Personas.
    "PERSONA_MODERN": "Modern",
    "PERSONA_TRADITIONAL": "Traditional",
    "PERSONA_ECLECTIC": "Eclectic",
    "PERSONA_GLAM": "Glam/Luxe",
    "PERSONA_COASTAL": "Coastal",
    "PERSONA_FARMHOUSE": "Farmhouse",

    // Persona Descriptions.
    "PERSONA_MODERN_DESCRIPTION": "Your dream home is an open floor plan with clean, simple lines. Chrome details and sophisticated accent pieces are artfully chosen for a more minimalistic approach.",
    "PERSONA_TRADITIONAL_DESCRIPTION": "Your dream home is simple, elegant and rooted in classic design. You opt for timeless styles with more ornamental accents.",
    "PERSONA_ECLECTIC_DESCRIPTION": "Your dream home is full of life, color and global flair. It's a fun, eclectic mix of expressive fabrics, patterns, textures and touches of greenery.",
    "PERSONA_GLAM_DESCRIPTION": "Your dream home sparkles and shines like the heart of downtown. Simple, monochromatic pieces are elevated with the addition of glitzy accessories.",
    "PERSONA_COASTAL_DESCRIPTION": "Your dream home is filled with natural light and conveys a breezy, summer state of mind, year-round. It includes natural textures mixed with blue and white decor.",
    "PERSONA_FARMHOUSE_DESCRIPTION": "Your dream home is straight out of Country Living Magazine: farmhouse chic and full of rustic pieces, natural wood accents and plenty of flowers.",

    // Persona Pincode Video Sources.
    "PERSONA_MODERN_PINCODE_VIDEO_SRC": "/videos/pincode-modern-en.webm",
    "PERSONA_TRADITIONAL_PINCODE_VIDEO_SRC": "/videos/pincode-traditional-en.webm",
    "PERSONA_ECLECTIC_PINCODE_VIDEO_SRC": "/videos/pincode-eclectic-en.webm",
    "PERSONA_GLAM_PINCODE_VIDEO_SRC": "/videos/pincode-glam-en.webm",
    "PERSONA_COASTAL_PINCODE_VIDEO_SRC": "/videos/pincode-coastal-en.webm",
    "PERSONA_FARMHOUSE_PINCODE_VIDEO_SRC": "/videos/pincode-farmhouse-en.webm",

    // Core.
    "CORE_DRAG_THE_CIRCLE": "Drag the Circle",
    "CORE_NEXT": "NEXT",
    "CORE_STYLE": "Style",
    "CORE_EXIT_BUTTON_LABEL": "Exit",
    "CORE_BACK_BUTTON_LABEL": "Back",
    "INSPIRED": "/images/inspired-by-pinterest-trends.svg",
    "INSPIRED_ALT": "Inspired by Pinterest Trends",

    // Intro Screen.
    "HOME_QUIZ_TITLE": "PERSONAL HOME STYLE QUIZ",
    "HOME_INTRO_TITLE": <>Discover a style<br />that's all you.</>,
    "HOME_BUTTON_LABEL": "TAP TO START",

    // Question 1 - Option 1.
    "Q1O1_THEME": "SET THE MOOD",
    "Q1O1_QUESTION": <>Where do you feel<br />“in your element”?</>,
    "Q1O1_ANSWER_1": "City",
    "Q1O1_ANSWER_2": "Nature",

    // Question 1 - Option 2.
    "Q1O2_THEME": "SET THE MOOD",
    "Q1O2_QUESTION": <>How would you<br />describe yourself?</>,
    "Q1O2_ANSWER_1": "Neat",
    "Q1O2_ANSWER_2": "Unorganized",

    // Question 2 - Option 1.
    "Q2O1_THEME": "DISCOVER YOUR INSPIRATION",
    "Q2O1_QUESTION": <>If you're given $1000 to buy a new piece<br />of art, which would you choose?</>,
    "Q2O1_MODERN": "Modern Painting",
    "Q2O1_TRADITIONAL": "Oil Painting",
    "Q2O1_ECLECTIC": "Ink Drawing",
    "Q2O1_GLAM": "Black & White Photography",
    "Q2O1_COASTAL": "Scenic Photograph",
    "Q2O1_FARMHOUSE": "Landscape Painting",

    // Question 2 - Option 2.
    "Q2O2_THEME": "DISCOVER YOUR INSPIRATION",
    "Q2O2_QUESTION": <>Which curtains best<br />suit your style?</>,
    "Q2O2_MODERN": "Blackout",
    "Q2O2_TRADITIONAL": "Draped",
    "Q2O2_ECLECTIC": "Patterned",
    "Q2O2_GLAM": "Shiny",
    "Q2O2_COASTAL": "Linen",
    "Q2O2_FARMHOUSE": "Sheer",

    // Question 3 - Option 1.
    "Q3O1_THEME": "DEFINE YOUR PERSONALITY",
    "Q3O1_QUESTION": <>What movie genre do you prefer?</>,
    "Q3O1_ANSWER_1": "Comedy",
    "Q3O1_ANSWER_2": "Drama",

    // Question 3 - Option 2.
    "Q3O2_THEME": "DEFINE YOUR PERSONALITY",
    "Q3O2_QUESTION": <>Where are you likely<br />to be on the weekend?</>,
    "Q3O2_ANSWER_1": "Outdoors",
    "Q3O2_ANSWER_2": "Indoors",

    // Question 4 - Option 1.
    "Q4O1_THEME": "TAP INTO YOUR STYLE",
    "Q4O1_QUESTION": <>If you could live anywhere,<br />what home would you choose?</>,
    "Q4O1_MODERN": "Contemporary",
    "Q4O1_TRADITIONAL": "Classic",
    "Q4O1_ECLECTIC": "Mediterranean",
    "Q4O1_GLAM": "Trendy",
    "Q4O1_COASTAL": "Beach",
    "Q4O1_FARMHOUSE": "Rustic",

    // Question 4 - Option 2.
    "Q4O2_THEME": "TAP INTO YOUR STYLE",
    "Q4O2_QUESTION": <>Where would you love to travel to?</>,
    "Q4O2_MODERN": "Spain",
    "Q4O2_TRADITIONAL": "London",
    "Q4O2_ECLECTIC": "Japan",
    "Q4O2_GLAM": "NYC",
    "Q4O2_COASTAL": "Hawaii",
    "Q4O2_FARMHOUSE": "Nashville",

    // Question 5 - Option 1.
    "Q5O1_THEME": "REFINE YOUR STYLE",
    "Q5O1_QUESTION": <>Choose an item to add<br />to your living room.</>,
    "Q5O1_MODERN": "Mirror",
    "Q5O1_TRADITIONAL": "Bookcase",
    "Q5O1_ECLECTIC": "Pillow",
    "Q5O1_GLAM": "Cabinet",
    "Q5O1_COASTAL": "Linen Curtains",
    "Q5O1_FARMHOUSE": "Floral Rug",

    // Question 5 - Option 2.
    "Q5O2_THEME": "REFINE YOUR STYLE",
    "Q5O2_QUESTION": <>Which lighting style would<br />you add to your home?</>,
    "Q5O2_MODERN": "Simple",
    "Q5O2_TRADITIONAL": "Brass",
    "Q5O2_ECLECTIC": "Pattern",
    "Q5O2_GLAM": "Glitzy",
    "Q5O2_COASTAL": "Glass",
    "Q5O2_FARMHOUSE": "Rustic",
    "Q5_BUTTON": "REVEAL STYLE",

    // Results.
    "PERSONA_PAGE_DISCOVER": "Discover",
    "PERSONA_PAGE_PINBOARD_LOADING": "LOADING YOUR PINTEREST BOARD",

    // Pinterest Style Board.
    "STYLEBOARD_PAGE_TAKE_THE_BOARD": "Like what you see? Take the Pinterest board with you.",
    "STYLEBOARD_PAGE_SCAN_PINCODE": <>GET THE <img src="/images/pinterest-icon.svg" alt="Pinterest" /> BOARD</>,
    "STYLEBOARD_PAGE_HEADER": "Scan Pincode to discover more.",
    "STYLEBOARD_PINCODE_CTA": "SEND LINK",
    "STYLEBOARD_PINCODE_REPLAY": "Replay",

    // SMS/Email
    "MODAL_AGE_GATE_HEADER": "Do you acknowledge that you are 18 years of age or older?",
    "MODAL_AGE_GATE_CONFIRM_BUTTON": "Yes, I am",
    "MODAL_AGE_GATE_CLOSE_BUTTON": "No, I am not",

    "MODAL_PINBOARD_HEADER_TITLE": "Enter your email or phone number.",

    "MODAL_PINBOARD_EMAIL_TAB_LABEL": "Email",
    "MODAL_PINBOARD_SMS_TAB_LABEL": "Text",

    "MODAL_PINBOARD_PRIVACY_LABEL": "I agree to the ",
    "MODAL_PINBOARD_PRIVACY_LINK_TEXT": "Privacy Policy",

    "MODAL_PINBOARD_EMAIL_TERMS_LABEL": "I understand by providing my email address, I agree to receiving up to two emails about \"JCPenney and Pinterest Personal Home Style Quiz\" results. (Data rates apply.)",
    "MODAL_PINBOARD_SMS_TERMS_LABEL": "I understand by providing my mobile phone number, I agree to receiving up to two text messages about \"JCPenney and Pinterest Personal Home Style Quiz\" results. (Message rates apply.)",

    "MODAL_PINBOARD_EMAIL_PLACEHOLDER": "myemail@email.com",
    "MODAL_PINBOARD_SMS_PLACEHOLDER": "+X (XXX) XXX-XXXX",
    "MODAL_PINBOARD_EMAIL_TEMPLATE_ID": "d-c4b0b08203e44806ba346d7a6c98074f",

    "MODAL_PINBOARD_PINTEREST_BUTTON_COPY": "Send Link",

    "MODAL_ERROR_EMAIL_NOT_SET_INVALID": "Invalid email.",
    "MODAL_ERROR_EMAIL_INVALID_FORMAT": "Incorrect format.",
    "MODAL_ERROR_SMS_NOT_SET_INVALID": "Invalid phone number.",
    "MODAL_ERROR_UNREACHABLE": "Message cannot be sent.",

    "MODAL_SUCCESS_EMAIL": "Email successfully sent to",
    "MODAL_SUCCESS_SMS": "Text message successfully sent to",

    "MODAL_SMS_BODY": "Thanks for participating and discovering your Penney's personal style! Check out your Pinterest Board below, featuring all your new favorite items. Happy shopping!\n\n",

    "MODAL_PRIVACY_HEADER_TITLE": "Privacy Policy",
    "MODAL_PRIVACY_CONTENT": Privacy.privacy,

    "MODAL_EXIT_HEADER": "Sure you want to leave?",
    "MODAL_EXIT_RESTART_BUTTON": "YES, I'LL LEAVE",
    "MODAL_EXIT_CLOSE_BUTTON": "NO, I'LL STAY",

    "MODAL_TIMEOUT_HEADER": "Still there?",
    "MODAL_TIMEOUT_IMG_ALT": "Timeout Clock",
    "MODAL_TIMEOUT_CLOSE_BUTTON": "YES, I'M HERE",
    "MODAL_TIMEOUT_RESTART_BUTTON": "Restart"
};
