// Modules.
import React, { useContext, useEffect, useRef } from 'react';
import { Transition } from 'react-transition-group';
import PropTypes from 'prop-types';
import TweenMax, { Power3 } from 'gsap';

// Components.
import Button from 'components/Button';
import LanguageSelector from 'components/LanguageSelector';
import HeaderTitle from 'components/HeaderTitle';

// Utils.
import { animateHandler } from './HomeSection.animations';
import GoogleAnalytics from 'utils/analytics';
import { localize } from 'utils/localization';
import AudioManager from 'utils/managers/AudioManager';
import AssetManager from 'utils/managers/AssetManager';
import { QuizContext } from 'utils/context';
import questions from 'json/questionnaire.config';

// Styles.
import styles from './HomeSection.module.scss';

const HomeSection = ({ history, match }) => {

    const node = useRef(null);
    const titleRef = useRef(null);
    const subtitleRef = useRef(null);
    const languageRef = useRef(null);
    const buttonRef = useRef(null);
    const context = useContext(QuizContext);
    const sectionIn = match !== null;

    const onAnimateOut = () => (
        new Promise(resolve => {
            TweenMax.to(node.current, 1, { opacity: 0, ease: Power3.easeOut, onComplete: () => resolve(true) });
        })
    );

    const onClick = () => {

        GoogleAnalytics.button.click('Home Start Button');
        GoogleAnalytics.start();
        AudioManager.play(AudioManager.state.SOUNDS.JUMP);
        context.setTimer( true );

        onAnimateOut().then(() => {
            history.push('/question/0');
        });

    };

    const videoInRef = useRef(null);
    const videoLoopRef = useRef(null);

    useEffect(() => {

        GoogleAnalytics.pageview('/');

        TweenMax.set(videoInRef.current, { autoAlpha: 1 });
        TweenMax.set(videoLoopRef.current, { autoAlpha: 0 });

        videoInRef.current.addEventListener('ended', () => {
            TweenMax.set(videoInRef.current, { autoAlpha: 0 });
            TweenMax.set(videoLoopRef.current, { autoAlpha: 1 });
            videoLoopRef.current.play();
        }, false);

        const images = questions.map(question => (
            question.map(q => (
                q.answers.map(answer => (
                    (answer.img || null)
                ))
            ))
        )).flat(2);

        AssetManager.load(images.filter(el => el != null));

    }, []);

    return (
        <Transition
            appear
            unmountOnExit
            in={ sectionIn }
            addEndListener={ (node, done) => {

                const refs = { titleRef, subtitleRef, buttonRef, languageRef };
                animateHandler(node, done, sectionIn, refs);

            }}
        >
            <section className={ styles.HomeSection } ref={ node }>
                <LanguageSelector ref={ languageRef } />

                <div className={ styles.backvid }>
                    <video src="/videos/intro-in.webm" ref={ videoInRef } autoPlay muted />
                </div>
                <div className={ styles.backvid }>
                    <video src="/videos/intro-loop.webm" ref={ videoLoopRef } loop muted />
                </div>

                <div className={ styles.container }>
                    <HeaderTitle ref={ subtitleRef }>{ localize('HOME_QUIZ_TITLE') }</HeaderTitle>
                    <h1 ref={ titleRef }>{ localize('HOME_INTRO_TITLE') }</h1>
                    <Button onClick={ onClick } large ref={ buttonRef }>{ localize('HOME_BUTTON_LABEL') }</Button>
                </div>
            </section>
        </Transition>
    );
};

HomeSection.propTypes = {
    history: PropTypes.object,
    match: PropTypes.object,
};

HomeSection.defaultProps = {
    history: null,
    match: null,
};

export default HomeSection;
