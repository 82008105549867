import React from 'react';

export default {
    "privacy": 
        <>
            <h1>Política de privacidad de Thinkingbox:</h1>
            <p>Thinkingbox Media & Design Inc. (en adelante, Thinkingbox) ha creado la experiencia jcpstylequiz.com. Thinkingbox no recopila, procesa ni almacena datos de usuario. Para enviar un enlace a un Tablero de Pinterest a través de SMS o correo electrónico, Thinkingbox utiliza los servicios administrativos de Twilio y SendGrid. Consulte a continuación los extractos de las políticas de privacidad de ambas entidades en relación con la recopilación específica de datos a través del servicio jcpstylequiz.com.</p>
            <p><strong>Cómo Twilio procesa su información personal</strong></p>
            <p>Su información personal generalmente aparece en la plataforma de Twilio de diferentes maneras:</p>
            <ul>
                <li>Información personal relacionada con las comunicaciones sobre sus usuarios finales, como los números de teléfono de sus usuarios finales para comunicaciones basadas en números, direcciones IP para comunicaciones basadas en IP o tokens de dispositivos para notificaciones automáticas, aparece en nuestros sistemas cuando usa o tiene la intención de usar esta información para contactar a su usuario final a través del uso de nuestros productos y servicios.</li>
                <li>La información personal de sus usuarios finales puede aparecer en «nombres descriptivos», que son cadenas que usted proporciona, si elige incluir la información personal de sus usuarios finales como parte de una cadena.</li>
                <li>La información personal de sus usuarios finales y de usted también puede aparecer en el contenido de las comunicaciones que usted, o sus usuarios finales, envíen o reciban utilizando los productos y servicios de Twilio.</li>
            </ul>
            <p>Llamamos a la información en los dos puntos anteriores Datos de Uso del Cliente. La información en el tercer punto es a lo que nos referimos como Contenido del Cliente.</p>
            <p>Como se señaló anteriormente, la ley de protección de datos (también conocida como ley de privacidad) en ciertas jurisdicciones, como el EEE, diferencia entre «controladores» y «procesadores» de información personal. Cuando Twilio procesa el contenido del cliente, generalmente actuamos como un procesador. Cuando procesamos los datos de uso del cliente, actuamos como un procesador en muchos aspectos, pero podemos actuar como un controlador en otros.</p>
            <p>Por ejemplo, es posible que necesitemos usar ciertos Datos de Uso del Cliente para fines legítimos de facturación, conciliación de facturas con operadores de telecomunicaciones y en el contexto de la resolución de problemas y la detección de problemas con la red.</p>
            <hr />
            <p><strong>¿Qué Datos de Uso del Cliente y qué Contenido del Cliente procesa Twilio y por qué?</strong></p>
            <p>Utilizamos los Datos de Uso del Cliente y el Contenido del Cliente para prestarle servicios y desempeñar las funciones necesarias de nuestro negocio como proveedor de servicios de comunicaciones. No vendemos la información personal de sus usuarios finales y no compartimos la información de sus usuarios finales con terceros para los fines comerciales de éstos.</p>
            <p>Para más información, consulte https://www.Twilio.com/legal/privacy</p>
            <p></p>
            <p>Fecha: 25 de abril de 2018 SendGrid, Inc. y su grupo de empresas (en adelante, «SendGrid», «nosotros», «nuestro» o «nos») se  comprometen a proteger su privacidad. Esta Política de privacidad de servicios establece cómo recopilamos y procesamos información personal a través de nuestra plataforma de envío de correo electrónico y prestación de servicios relacionados, incluyendo desarrollo, transmisión, análisis y administración de mensajes de correo electrónico y otras comunicaciones y herramientas digitales (en adelante, los «servicios») y, en general, en los negocios, como cuando una persona que se ha registrado para usar los servicios (en adelante, el «cliente»), un cliente potencial u otro tercero se involucra con nosotros en relación con la prestación de los servicios (colectivamente, «usted»).</p>
            <p>Tenga en cuenta que, a los efectos de la legislación de protección de datos de la UE, SendGrid es el controlador de la información personal de usted. Esta Política de privacidad de servicios (esta «política») no cubre la manera en que SendGrid recopila y utiliza la información personal recopilada de las personas que visitan nuestro sitio web (en adelante, los «visitantes»), como se describe por separado en nuestra Política de privacidad del sitio web.</p>
            <hr />
            <ol>
                <li>
                    <p><strong> Información recopilada a través de los servicios</strong></p>
                    <p>A través de nuestros servicios, SendGrid recopila cierta información personal de la siguiente manera:</p>
                    <ul>
                        <li>
                            <p>Información que los clientes nos brindan: puede proporcionarnos información personal a través de los servicios; por ejemplo, cuando crea una cuenta de SendGrid, utiliza los servicios para enviar mensajes por correo electrónico u otras comunicaciones, comunicarse con el servicio de atención al cliente, enviarnos un mensaje por correo electrónico o comunicarse con nosotros de cualquier otra manera. Al configurar una cuenta de SendGrid, se le pedirá que proporcione cierta información básica, como un nombre, dirección de correo electrónico, nombre de usuario, contraseña, nombre de la empresa, ocupación, ubicación y número de teléfono (por ejemplo, si está utilizando dos factores de autenticación). Si compra uno de nuestros planes de pago, es posible que también deba proporcionarnos información de pago y facturación, como los detalles de la tarjeta de crédito y la dirección de facturación del cliente.</p>
                            <p>Como cliente, SendGrid también mantendrá un registro de sus compras, información transaccional, historial y uso de los servicios, y cualquier comunicación y respuesta. También podemos recopilar información personal, como su información de contacto, detalles de trabajo y comentarios, cuando asista a nuestros eventos, participe en encuestas o mediante otras interacciones comerciales o de marketing que podamos tener con usted.</p>
                        </li>
                        <li>
                            <p>Información que recopilamos automáticamente a través de los servicios: cuando utiliza los servicios, podemos recopilar cierta información automáticamente sobre su dispositivo y su uso de los servicios.  Esto incluirá direcciones IP, tipos de navegador, archivos de registro y otra información sobre su sistema y conexión. Recopilamos información sobre cómo accede y utiliza los servicios, como qué páginas se ven y qué partes de los servicios se utilizan.</p>
                            <p>También recopilamos información sobre el desempeño de los servicios, incluidas las métricas relacionadas con la entrega de mensajes por correo electrónico y otras comunicaciones electrónicas que usted envía a través de la plataforma SendGrid. Esta información nos permite mejorar el contenido y el funcionamiento de los servicios, y facilitar la investigación y el análisis de los mismos.</p>
                            <p>Tenga en cuenta que utilizamos balizas web en nuestros mensajes de correo electrónico entregados a los destinatarios mediante la plataforma SendGrid. Estas balizas web rastrean ciertos comportamientos, como por ejemplo si el mensaje por correo electrónico enviado a través de la plataforma SendGrid se entregó, se abrió, se hizo clic en él, si rebotó o se trató como correo no deseado.</p>
                            <p>Esto nos permite medir el desempeño de las campañas de correo electrónico de nuestros clientes y proporcionar información analítica y mejorar la eficacia de nuestros servicios. Recopilamos esta información automáticamente mediante el uso de diversas tecnologías de recopilación de información de uso común, incluidas cookies y balizas web, para reunir información a medida que los usuarios y visitantes navegan por el sitio web y utilizan los servicios (en adelante, la «información de navegación del sitio web»).</p>
                            <p>Utilizamos estas tecnologías para analizar tendencias, administrar sitios web y servicios, rastrear los movimientos de los usuarios y visitantes en nuestro sitio web y servicios, publicar anuncios específicos y recopilar información demográfica sobre nuestra base de usuarios y visitantes en general. Información adicional sobre nuestro uso de la información de navegación del sitio web está disponible en https://SendGrid.com/policies/privacy/website-navigational-information/.</p>
                        </li>
                        <li>
                            <p>Información que procesamos en nombre de nuestros clientes: Los clientes de SendGrid también pueden ingresar o cargar cierta información personal a la plataforma SendGrid que procesaremos como procesador de datos en nombre de nuestros clientes.</p>
                            <p>Por ejemplo, cuando nuestros clientes utilizan nuestra plataforma de entrega por correo electrónico para cargar información de contacto relacionada con sus destinatarios, otra información personal sobre los destinatarios, o cargar contenidos de comunicaciones por correo electrónico, o crear campañas de marketing, actuaremos como un procesador de datos y solo procesaremos información en nombre de nuestros clientes y de acuerdo con sus instrucciones (como se define en los acuerdos de nuestro cliente).</p>
                            <p>Utilizaremos dicha información personal para: proporcionar y entregar los servicios al cliente, prevenir o abordar cualquier problema técnico o de servicio, responder a una solicitud, instrucción o solicitud de atencion al cliente, o para cualquier otro propósito previsto en el acuerdo del cliente, o de acuerdo con o según lo exija la ley.</p>
                            <p>En tales casos, es el cliente (o, según el caso, otros terceros) quien sigue siendo responsable del manejo de la información personal y del cumplimiento de las leyes de privacidad de datos aplicables. Si tiene preguntas o inquietudes sobre cómo se maneja dicha información personal, debe comunicarse con la entidad relevante que está utilizando los servicios y consultar sus políticas de privacidad separadas.</p>
                        </li>
                        <li>
                            <p>Información que recopilamos de otras fuentes: Periódicamente, podemos recibir información personal sobre usted de fuentes de terceros (incluidas bases de datos públicas, nuestros socios de marketing conjuntos y plataformas de redes sociales), pero solo cuando hemos comprobado que estos terceros tienen su consentimiento o están legalmente autorizados u obligados a revelarnos su información personal. Los tipos de información personal que recopilamos de terceros incluyen nombre, título, nombre de la empresa, función laboral, experiencia, número de teléfono o dirección de correo electrónico y utilizamos la información que recibimos de estos terceros para mejorar los servicios, la precisión de los registros que tenemos sobre usted y las ofertas relevantes de marketing y otras.</p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p><strong>Uso de la información</strong></p>
                    <p>Podemos utilizar la información que recopilamos a través de los servicios por una variedad de razones, que incluyen:</p>
                    <ul>
                        <li>Proporcionar, operar, optimizar y mantener los servicios;</li>
                        <li>Atender las consultas de los clientes y las solicitudes de soporte, y proporcionar información y acceso a los recursos o servicios que nos han solicitado;</li>
                        <li>Administrar la plataforma SendGrid, el sistema y la seguridad;</li>
                        <li>Procesar y completar transacciones;</li>
                        <li>Compilar estadísticas resumidas sobre la operación y el uso de los servicios y comprender mejor las preferencias de nuestros clientes;</li>
                        <li>Enviar a nuestros clientes alertas técnicas, informes, actualizaciones, notificaciones de seguridad y otras comunicaciones relacionadas con los servicios;</li>
                        <li>Llevar a cabo investigación y desarrollo para mejorar nuestros productos y servicios;</li>
                        <li>Enviarle comunicaciones de marketing (cuando esto esté de acuerdo con sus preferencias de comunicación);</li>
                        <li>Investigar y prevenir el fraude, el acceso o uso no autorizado de los servicios, el incumplimiento de los términos y las políticas y otros comportamientos ilícitos;</li>
                        <li>Llevar a cabo otros fines comerciales legítimos, así como otros fines legales sobre los cuales le notificaremos.</li>
                    </ul>
                </li>
                <li>
                    <p><strong>Base legal para el procesamiento (solo individuos del EEE)</strong></p>
                    <p></p>
                    <p>Si usted pertenece al Espacio Económico Europeo (el «EEE»), nuestra base legal para recopilar y utilizar la información personal descrita anteriormente dependerá de la información personal en cuestión y del contexto específico en el que la recopilamos.</p>
                    <p>En general, recopilaremos información personal de usted solamente si tenemos su consentimiento para ello, si necesitamos la información para ejecutar un contrato con usted, o en beneficio de nuestros intereses legítimos y si esto no se ve reemplazado por sus intereses de protección de datos o sus derechos y libertades fundamentales. En algunos casos, también podemos tener la obligación legal de recopilar la información personal en cuestión.</p>
                    <p>Si le solicitamos que brinde información personal para cumplir con un requisito legal o para ejecutar un contrato con usted, lo aclararemos en el momento pertinente y le informaremos si la provisión de su información personal es obligatoria o no (así como de las posibles consecuencias si no proporciona su información personal).</p>
                    <p>Del mismo modo, si recopilamos y usamos su información personal en función de nuestros intereses legítimos o los de un tercero y dichos intereses no están enumerados anteriormente (en la sección «Uso de la información»), le dejaremos claro en el momento pertinente cuáles son esos intereses legítimos.</p>
                    <p>Si tiene preguntas o necesita más información sobre la base legal sobre la que recopilamos y usamos su información personal, contáctenos utilizando los datos de contacto provistos en la sección 14 a continuación.</p>
                </li>
                <li>
                    <p><strong>Información que compartimos</strong></p>
                    <p>Podemos compartir y divulgar su información personal a terceros en las siguientes circunstancias:</p>
                    <ul>
                        <li>Dentro del grupo de empresas SendGrid para los fines descritos en esta política.</li>
                        <li>A vendedores y otros proveedores de servicios externos que requieren acceso a su información personal para ayudar en la provisión de los servicios y otras funciones relacionadas con el negocio. Por ejemplo, usamos varios subprocesadores en la prestación de nuestros servicios.</li>
                        <li>A socios y patrocinadores: Ciertas partes de los servicios pueden ofrecerse en relación con otras compañías que son socios o patrocinadores. Cuando utiliza este tipo de servicios, nos aseguraremos de que la identidad de esos socios o patrocinadores sea claramente visible y de que tenga la oportunidad de no proceder o darse de baja. Dicha información se manejará de acuerdo con las políticas de privacidad de ellos y, si tiene alguna pregunta, debe comunicarse directamente con el socio o patrocinador.</li>
                        <li>También podemos divulgar su información a terceros: (a) cuando lo exija la ley o los requisitos reglamentarios, una orden judicial u otra autorización judicial, (b) en respuesta a solicitudes legales de las autoridades públicas, incluso para cumplir con requisitos de seguridad nacional y de aplicación de la ley; (c) en relación con la venta,  transferencia, fusión, quiebra, reestructuración u otra reorganización de un negocio; (d) para proteger o defender nuestros derechos, intereses o propiedad, o los de terceros; (e) investigar cualquier irregularidad en relación con el sitio web o nuestros servicios; o (f) para proteger los intereses vitales de un individuo.</li>
                        <li>Con su consentimiento.</li>
                    </ul>
                </li>
                <li>
                    <p>También podemos compartir información personal recopilada a través de los servicios con compañías externas para que puedan ofrecer productos y servicios que creemos serán de su interés. También podemos compartir información personal con compañías externas que ofrecen publicidad para orientar mejor los anuncios y otro contenido que se muestra en nuestro sitio y para hacerle ofertas que creemos que pueden ser de su interés.</p>
                    <p>Puede optar por no divulgar su información a terceros con fines de marketing directo; consulte la sección «Elección y exclusión voluntaria», a continuación. Además, los anunciantes externos pueden recibir cierta información personal sobre usted si hace clic en los anuncios que se muestran en nuestro sitio web o los servicios. Si no desea que estos terceros procesen su información personal, no haga clic en estos anuncios.</p>
                </li>
                <li>
                    <p><strong>Sitios de terceros</strong></p>
                    <p>Nuestros servicios pueden contener enlaces a otros sitios web y servicios que están fuera de nuestro control y no están cubiertos por esta política. Por ejemplo, podemos asociarnos con otras compañías para que pueda integrar SendGrid con otras cuentas de terceros. Aunque procuramos insertar enlaces únicamente a servicios que comparten nuestras rigurosas normas de confidencialidad y respeto a la privacidad, no somos responsables del contenido, la seguridad o las prácticas de privacidad empleadas por esos otros sitios web. Si accede a otros sitios web utilizando los enlaces provistos, los operadores de estos sitios web pueden recopilar información suya que será utilizada por ellos de acuerdo con su política de privacidad, la cual puede diferir de la nuestra.</p>
                </li>
                <li>
                    <p><strong>Nuestra seguridad</strong></p>
                    <p>Mantenemos medidas de seguridad razonables y apropiadas para proteger su información personal de pérdidas, mal uso y acceso no autorizado, divulgación, alteración y destrucción. Tomaremos todas las medidas razonables para garantizar la seguridad de su información personal. Para obtener más información sobre nuestras prácticas de seguridad, consulte https://SendGrid.com/policies/security/.</p>
                </li>
                <li>
                    <p><strong>Datos sensibles</strong></p>
                    <p>SendGrid no solicita ni recopila a sabiendas —y usted no debe proporcionar— ninguna información sobre la condición médica o de salud, la raza u origen étnico, las opiniones políticas, creencias religiosas o filosóficas u otros datos confidenciales de una persona, según la definición de dicho término en los Términos del servicio de SendGrid.</p>
                </li>
                <li>
                    <p><strong>Información sobre transferencias internacionales</strong></p>
                    <p></p>
                    <p>Podemos almacenar y procesar su información personal en cualquier país donde tengamos instalaciones o donde contratemos proveedores de servicios, y al leer esta política y utilizar los servicios, usted reconoce que su información personal puede transferirse a países fuera de su país de residencia, incluidos los Estados Unidos, que pueden tener reglas de protección de datos diferentes a las de su país. Cuando lo hagamos, nuestra recopilación, almacenamiento y uso de su información personal estará de acuerdo con los propósitos establecidos en esta política.</p>
                    <p>En cuanto a la información personal que recibimos del EEE y Suiza, SendGrid, Inc. participa y cumple con el Marco de Escudo de Privacidad entre la UE y los EE. UU. y entre Suiza y los EE. UU., según lo establecido por el Departamento de Comercio de los EE. UU. Puede encontrar más información sobre nuestro compromiso con los principios de la protección de privacidad en nuestro  Aviso de certificación de escudo de privacidad. Si hubiera algún conflicto entre los términos de esta política de privacidad y los Principios del Escudo de Privacidad, prevalecerán los Principios del Escudo de Privacidad.</p>
                    <p>Nos aseguraremos de que, cuando otros afiliados no pertenecientes al EEE o proveedores de servicios externos tengan acceso a información personal fuera del EEE, ellos también se comprometan a proporcionar el mismo nivel de protección que los Principios del Escudo de Privacidad.<br />Si su uso de los servicios requiere que SendGrid procese datos personales que caen dentro del alcance de GDPR, el Anexo de procesamiento de datos de SendGrid está disponible para firma electrónica aquí.</p>
                    <p>Además, tiene disponible más información sobre las leyes de protección de datos de la UE al visitar https://SendGrid.com/resource/general-data-protection-regulation/.</p>
                </li>
                <li>
                    <p><strong>Niños</strong></p>
                    <p>Los servicios no están dirigidos a niños menores de 16 años. No recopilamos deliberadamente información personal de niños menores de 16 años.</p>
                </li>
                <li>
                    <p><strong>Elección y exclusión voluntaria</strong></p>
                    <p>Puede optar por no recibir nuestros mensajes de correo electrónico de marketing en cualquier momento, o bien puede optar por no compartir su información de contacto con terceros con fines de marketing directo. Puede optar por no recibir nuestros mensajes de correo electrónico de marketing haciendo clic en el enlace «cancelar suscripción» en la parte inferior de nuestros mensajes de marketing.</p>
                    <p>Además, todas las solicitudes de exclusión pueden hacerse enviándonos un correo electrónico a datasubjectrequests@sendgrid.com. </p>
                    <p>Tenga en cuenta que puede tardar hasta 3 días eliminar su información de contacto de nuestras listas de comunicaciones de marketing, por lo que puede recibir correspondencia de nosotros por un corto tiempo después de hacer su solicitud. Tenga en cuenta que algunas comunicaciones (como mensajes de servicio, notificaciones de cuenta, información de facturación) se consideran transaccionales y necesarias para la administración de la cuenta y no puede optar por no recibir estos mensajes a menos que solicite desactivar su cuenta de SendGrid.</p>
                </li>
                <li>
                    <p><strong>Otros derechos de datos</strong></p>
                    <p>SendGrid toma medidas razonables para garantizar que los datos que recopilamos sean confiables para el uso previsto y que sean precisos, completos y que estén actualizados. Puede administrar muchas de sus configuraciones de cuenta y perfil dentro del tablero provisto a través de la plataforma SendGrid. Sin embargo, si desea contactarnos directamente para acceder, cambiar o eliminar su información personal, o para alterar sus datos o preferencias de marketing, envíenos un mensaje por correo electrónico a datasubjectrequests@sendgrid.com.</p>
                    <p>Consideraremos su solicitud de acuerdo con las leyes correspondientes. Si usted es de ciertos territorios (como la Unión Europea), es posible que tenga derechos de protección de datos adicionales, como derechos más amplios para acceder y eliminar su información personal, así como derechos para:</p>
                    <ul>
                        <li>Restricción: puede solicitarnos que restrinjamos el procesamiento de su información en ciertas circunstancias;</li>
                        <li>Objeción: puede oponerse a un mayor procesamiento de su información personal en ciertas circunstancias;</li>
                        <li>Retiro del consentimiento: cuando dependemos de su consentimiento para procesar su información personal, tiene derecho a rechazar el consentimiento y/o, si se proporciona, a retirarlo en cualquier momento. Esto no afectará la legalidad del procesamiento antes del retiro de su consentimiento.</li>
                        <li>Portabilidad: puede solicitarnos que proporcionemos su información de forma estructurada, de uso común y legible por máquina en determinadas circunstancias.</li>
                    </ul>
                </li>
                <li>
                    <p>Si desea ejercer los derechos anteriores, escríbanos utilizando los datos de contacto en la sección 14 a continuación y consideraremos su solicitud de acuerdo con las leyes de protección de datos correspondientes. Podemos pedirle que verifique su identidad para ayudarnos a responder a su solicitud.</p>
                    <p>También tiene derecho a plantear preguntas o quejas ante su autoridad de protección de datos o la Information Commissioner's Office (la Oficina del Comisionado de Información) en el Reino Unido en cualquier momento.</p>
                    <p>Information Commissioner's Office Wycliffe House Water Lane Wilmslow Cheshire SK9 5AF</p>
                    <p>Teléfono: 0303 123 1113 Fax: 01625 524 510 Correo electrónico: casework@ico.org.uk</p>
                    <p>Tenga en cuenta que si procesamos su información personal en nombre de nuestros clientes como procesador de datos (véase la sección 1 (c), «Información que procesamos en nombre de nuestros clientes»), luego debe dirigir cualquier solicitud de derechos de datos a nuestro cliente que posee la cuenta de SendGrid.  No podremos responder directamente a tales solicitudes y remitiremos dichas solicitudes al cliente correspondiente.</p>
                </li>
                <li>
                    <p><strong>Sus derechos de privacidad en California</strong></p>
                    <p></p>
                    <p>La ley de California establece que los residentes de California tienen derecho a solicitar que las empresas les digan cómo se ha compartido su información de identificación personal con terceros para los fines de marketing directo de dichos terceros.</p>
                    <p>Sin embargo, hay una excepción a este requisito para las empresas que han adoptado y revelado, en su política de privacidad, una política de no revelar la información de identificación personal de una persona a terceros para fines de marketing directo de dichos terceros si esa persona ha optado por no divulgar su información de identificación personal a terceros para dichos fines. SendGrid ha adoptado una política que le permite optar por no divulgar su información de identificación personal a terceros para sus fines de marketing directo y, por lo tanto, cae dentro de esta excepción. Las instrucciones para optar por no participar se pueden encontrar en la sección titulada que se encuentra en la sección Elección y exclusión voluntaria que se encuentra arriba.</p>
                </li>
                <li>
                    <p><strong>Retención de la información personal</strong></p>
                    <p>Retendremos su información personal durante el período necesario para cumplir con los propósitos descritos en esta política, a menos que la ley exija o permita un período de retención más largo, por razones legales, impositivas o regulatorias, u otros fines legales.</p>
                    <p>Cuando no tengamos una necesidad comercial legítima continua de procesar su información personal, la eliminaremos o la anonimizaremos o, si esto no es posible (por ejemplo, porque su información personal se ha almacenado en archivos de respaldo), entonces almacenaremos su información de forma segura y la aislaremos de cualquier procesamiento posterior hasta que sea posible eliminarla.</p>
                    <p>Cualquier información personal que procesemos en nombre de nuestros clientes se conserva de acuerdo con los plazos establecidos en los acuerdos relevantes del cliente.</p>
                </li>
                <li>
                    <p><strong>Cambios a esta política</strong></p>
                    <p>Nos reservamos el derecho de cambiar o actualizar esta política periódicamente. Si se realizan cambios sustanciales, colocaremos un aviso destacado en nuestro sitio web o servicios al menos 30 días antes de que el cambio entre en vigencia, o nos comunicaremos con usted directamente por correo electrónico o a través de los servicios, y actualizaremos la última fecha revisada en la parte superior de esta política. Lo alentamos a que vuelva regularmente a esta página para asegurarse de estar actualizado con cualquier cambio.</p>
                </li>
                <li>
                    <p><strong>Cómo contactarnos</strong></p>
                    <p>Si tiene alguna pregunta o inquietud con respecto al uso o la divulgación de su información personal a través de los servicios, puede contactarnos enviando un mensaje por correo electrónico a datasubjectrequests@sendgrid.com, a nuestro oficial de protección de datos a dpo@sendgrid.com, o por correo a cualquiera de las siguientes direcciones:</p>
                    <p>SendGrid, Inc. 1801 California St., Suite 500 Denver, CO 80202, U.S.A.</p>
                    <p>SendGrid UK Limited 6th Floor One London Wall London EC2Y 5EB, UK</p>
                </li>
            </ol>        
            <p>Para más información, consulte  https://SendGrid.com/policies/privacy/services-privacy-policy/</p>
        </>
};